import { Container, Theme, Typography } from "@mui/material";
import { Button } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "80px",
    paddingBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "25px",
      paddingBottom: "15px"
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "2500px!important"
    },
    [theme.breakpoints.down("xl")]: {
      maxWidth: "1800px!important"
    }
  }
}));

export default function ContainerWrapper({ children }: any) {
  const classes = useStyles();

  return (
    <Container fixed maxWidth="xl" className={classes.container}>
      {children}
    </Container>
  );
}

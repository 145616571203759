import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import { StyledTableRow } from "../../components/Table/Table";
import { formatDate } from "../../utils/date";
import Status from "../../components/Status/Status";
import DownloadButton from "../../components/DownloadButton/DownloadButton";
import { calculateSum } from "../../utils";

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    fontSize: "16px!important",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px!important"
    }
  }
}));

export default function Row(props: { row: any }) {
  const classes = useStyles();

  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledTableRow
        key={row.id}
        onClick={() => setOpen(!open)}
        sx={{ "& > *": { borderBottom: "unset" }, cursor: "pointer" }}
      >
        <TableCell size="small" width={20}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.tableRow} align="center">
          {formatDate(row.created_at)}
        </TableCell>
        <TableCell className={classes.tableRow} align="center">
          {row.paid_at ? formatDate(row.paid_at) : null}
        </TableCell>
        <TableCell className={classes.tableRow} align="left">
          {row.customer.name}
        </TableCell>
        <TableCell className={classes.tableRow} align="center">
          <Status status={row.status} />
        </TableCell>
        <TableCell className={classes.tableRow} align="center">
          {new Intl.NumberFormat("pt-PT", {
            style: "currency",
            currency: "EUR"
          }).format(row.value)}
        </TableCell>
        <TableCell className={classes.tableRow} align="center">
          {row.urlReceipt && <DownloadButton url={row.urlReceipt} />}
        </TableCell>
      </StyledTableRow>
      {open && (
        <TableCell colSpan={8} padding="none">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: "20px 0" }}>
              <Table size="small" aria-label="purchases" sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell width={"50%"}>Descrição</TableCell>
                    <TableCell sx={{ textAlign: "center" }} width={"5%"}>
                      Quantidade
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }} width={"10%"}>
                      Valor
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.items.map((item: any, i: any) => (
                    <TableRow key={`${item.code}-${i}`}>
                      <TableCell component="th" scope="row">
                        {item.description}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {item.quantity}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {new Intl.NumberFormat("pt-PT", {
                          style: "currency",
                          currency: "EUR"
                        }).format(item.value)}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <b>Total</b>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <b>{calculateSum(row.items, "quantity")}</b>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <b>
                        {new Intl.NumberFormat("pt-PT", {
                          style: "currency",
                          currency: "EUR"
                        }).format(calculateSum(row.items, "value"))}
                      </b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      )}
    </>
  );
}
/*   */

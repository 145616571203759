import { Link } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default function DownloadButton({ url }: any) {
  return url ? (
    <Link
      onClick={() => {
        window.location.assign(url);
      }}
      style={{
        cursor: "pointer"
      }}
    >
      <FileDownloadIcon style={{ width: "26px", height: "26px" }} />
    </Link>
  ) : (
    <ErrorOutlineIcon style={{ width: "26px", height: "26px" }} />
  );
}

import React from "react";
import Video from "twilio-video";
import { Container, Link, Typography, Paper, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Header from "../../../Header";

const useStyles = makeStyles({
  container: {
    marginTop: "2.5em"
  },
  paper: {
    padding: "1em"
  },
  heading: {
    marginBottom: "0.4em"
  }
});

export default function UnsupportedBrowserWarning({
  children
}: {
  children: React.ReactElement;
}) {
  const classes = useStyles();

  if (!Video.isSupported) {
    return (
      <>
        <Header />
        <Container>
          <Grid container justifyContent="center" className={classes.container}>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <Typography variant="h4" className={classes.heading}>
                  Navegador não suportado
                </Typography>
                <Typography>
                  Para entrar na videoconsulta recorra, por favor, a um dos
                  seguintes navegadores: Chrome, Safari, Firefox ou Edge.
                </Typography>
                <Typography>Muito obrigado!</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }

  return children;
}

import TableComponent, { StyledTableRow } from "../../components/Table/Table";
import { tableHeaders } from "./utils";
import { TableCell } from "@mui/material";

export default function TableBillingHistory({ data }: any) {
  const sumDoctor = data.reduce((accumulator: any, object: any) => {
    return accumulator + object.totalDoctorValue;
  }, 0);
  console.log(data);
  return (
    <TableComponent headerCfg={tableHeaders}>
      {(data as any).map((row: any) => (
        <StyledTableRow key={row.id}>
          <TableCell align="center">
            {row.startDate} - {row.endDate}
          </TableCell>
          <TableCell align="center">
            {new Intl.NumberFormat("pt-PT", {
              style: "currency",
              currency: "EUR"
            }).format(row.totalDoctorValue)}
          </TableCell>
        </StyledTableRow>
      ))}
      <StyledTableRow>
        <TableCell align="center">
          <b>Total</b>
        </TableCell>
        <TableCell align="center">
          <b>
            {new Intl.NumberFormat("pt-PT", {
              style: "currency",
              currency: "EUR"
            }).format(sumDoctor)}
          </b>
        </TableCell>
      </StyledTableRow>
    </TableComponent>
  );
}

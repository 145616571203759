import React from "react";
import { Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Swoosh from "./swoosh";

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgb(40, 42, 43)",
    height: "100%"
  },
  container: {
    position: "relative",
    flex: "1"
  },
  innerContainer: {
    display: "flex",
    width: "888px",
    height: "379px",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px 0px rgba(40, 42, 43, 0.3)",
    overflow: "hidden",
    position: "relative",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      display: "block",
      height: "auto",
      width: "calc(100% - 40px)",
      margin: "auto",
      maxWidth: "400px"
    }
  },
  swooshContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(#03C1AF 40%, #00F5DE 100%)",
    backgroundSize: "cover",
    width: "296px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "100px"
    }
  },
  logoContainer: {
    position: "absolute",
    width: "195px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",
      textAlign: "initial",
      "& svg": {
        height: "64px"
      }
    }
  },
  logo: {
    width: "195px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",
      width: "90%",
      textAlign: "initial",
      "& svg": {
        height: "64px"
      }
    }
  },
  content: {
    background: "white",
    width: "100%",
    padding: "3em 4em",
    flex: 1,
    [theme.breakpoints.down("md")]: {
      padding: "2em"
    }
  },
  title: {
    color: "white",
    margin: "1em 0 0",
    [theme.breakpoints.down("md")]: {
      display: "none",
      margin: 0,
      fontSize: "1.65rem"
    }
  }
}));

interface IntroContainerProps {
  children: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.swooshContainer}>
            <div className={classes.logoContainer}>
              <img className={classes.logo} src="/logo_scribo.png" alt="next" />
              <Typography variant="h6" className={classes.title}>
                Videoconsulta
              </Typography>
            </div>
          </div>
          <div className={classes.content}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default IntroContainer;

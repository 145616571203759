import Tabs from "@mui/material/Tabs";
import Tab, { TabProps } from "@mui/material/Tab";
import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";
import Loading from "../Loading/Loading";

type TabsComponentProps = {
  value: number;
  handleChange: (value: number) => void;
  tabs: ({ contentBody: ReactElement } & TabProps)[];
  loading?: boolean;
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function TabsComponent({
  value,
  handleChange,
  tabs,
  loading = false
}: TabsComponentProps) {
  const handleTab = (_: any, value: number) => handleChange(value);

  return (
    <>
      <Tabs
        value={value}
        onChange={handleTab}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
        variant="scrollable"
      >
        {tabs.map((tab, i) => (
          <Tab component={tab.children} value={i} {...tab} />
        ))}
      </Tabs>
      {loading ? (
        <Box
          alignItems={"center"}
          justifyContent={"center"}
          display={"flex"}
          marginTop={10}
        >
          <Loading />
        </Box>
      ) : (
        tabs.map((tab, i) => (
          <TabPanel value={value} index={i}>
            {tab.contentBody}
          </TabPanel>
        ))
      )}
    </>
  );
}

import { useState } from "react";
import { useUserState } from "../state/user";
import { getDownloadFileCommissionsHistory } from "../services/payments";

export default function useDownloadFileCommissionsHistory() {
  const { token } = useUserState();
  const [loading, setLoading] = useState(false);

  const onClick = async ({
    dateIn,
    dateEnd
  }: {
    dateIn?: string;
    dateEnd?: string;
  }) => {
    try {
      setLoading(true);

      const result = await getDownloadFileCommissionsHistory({
        dateIn,
        dateEnd,
        authToken: token
      });

      return result;
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return { onClick, loading };
}

import { Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  barTop: {
    width: "100%",
    height: "100px",
    backgroundColor: "rgb(3, 193, 175, 0.4)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      height: "150px"
    }
  },
  logoScribo: {
    width: "185px",
    marginRight: "30px",
    [theme.breakpoints.up("md")]: {
      width: "285px",
      marginRight: "50px"
    }
  },
  logoKenetto: {
    width: "145px",
    [theme.breakpoints.up("md")]: {
      width: "245px"
    }
  }
}));

export default function Header() {
  const classes = useStyles();

  return (
    <div className={classes.barTop}>
      <img className={classes.logoScribo} src="/logo_scribo.png" alt="next" />
      <img className={classes.logoKenetto} src="/logo.svg" alt="next" />
    </div>
  );
}

import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import Calendar from "@mui/icons-material/Event";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import { DateRange } from "@mui/x-date-pickers-pro";
import moment, { Moment } from "moment";
import { isMobile } from "../../utils";

const shortcutsItems: PickersShortcutsItem<DateRange<Moment>>[] = [
  {
    label: "Últimos 7 dias",
    getValue: () => {
      return [moment().subtract(7, "day"), moment()];
    }
  },
  {
    label: "Este mês",
    getValue: () => {
      return [moment().startOf("month"), moment()];
    }
  },
  {
    label: "Últimos 3 meses",
    getValue: () => {
      return [moment().subtract(3, "months"), moment()];
    }
  },
  {
    label: "Últimos 6 meses",
    getValue: () => {
      return [moment().subtract(6, "months"), moment()];
    }
  },
  {
    label: "Último ano",
    getValue: () => {
      return [moment().subtract(1, "years"), moment()];
    }
  },
  {
    label: "Últimos 5 ano",
    getValue: () => {
      return [moment().subtract(5, "years"), moment()];
    }
  }
];

export default function DateRangePickerComponent({
  titles = ["Data Inicio", "Data Fim"],
  onChange,
  value
}: any) {
  return (
    <DateRangePicker
      localeText={{
        start: titles[0],
        end: titles[1]
      }}
      disableFuture={true}
      onChange={newValue => {
        onChange(newValue);
      }}
      value={value}
      calendars={3}
      {...(!isMobile ? { currentMonthCalendarPosition: 3 } : {})}
      sx={{
        width: "100%"
      }}
      label={"Data Inicio - Data Fim"}
      slots={{ field: SingleInputDateRangeField }}
      slotProps={{
        textField: { InputProps: { endAdornment: <Calendar /> } },
        shortcuts: {
          items: shortcutsItems
        },
        actionBar: { actions: [] }
      }}
    />
  );
}

import moment from "moment";

const filtersMobileInputs = [
  {
    id: "dates",
    size: {
      xs: 12,
      sm: 6
    },
    component: "dateRange",
    props: {
      titles: ["Data Inicio", "Data Fim"]
    }
  }
];

const filtersDesktopInputs = [
  {
    id: "dates",
    size: {
      sm: 4,
      md: 4,
      lg: 3
    },
    component: "dateRange",
    props: {
      titles: ["Data Inicio", "Data Fim"]
    }
  }
];

const tableHeaders = [
  {
    label: "Período de Faturação"
  },
  {
    label: "Comissão"
  },
  {
    label: "Fatura-Recibo"
  }
];

function groupByWeekAndSum(payments: any) {
  const groupedPayments: any = [];

  if (!payments?.length) return [];

  payments.forEach((payment: any) => {
    const paidAtDate = new Date(payment.paid_at);
    const year = paidAtDate.getFullYear();
    const weekNumber = moment(paidAtDate).isoWeek();

    const weekStartDate = getWeekStartDate(year, weekNumber);
    const weekEndDate = getWeekEndDate(year, weekNumber);

    const weekObject: any = {
      startDate: weekStartDate,
      endDate: weekEndDate,
      totalScriboValue: 0,
      totalDoctorValue: 0,
      payments: []
    };

    const existingWeek = groupedPayments.find(
      (week: any) => week.startDate === weekObject.startDate
    );

    if (!existingWeek) {
      weekObject.totalScriboValue += payment.scriboValue;
      weekObject.totalDoctorValue += payment.doctorValue;
      weekObject.payments.push(payment);
      groupedPayments.push(weekObject);
    } else {
      existingWeek.totalScriboValue += payment.scriboValue;
      existingWeek.totalDoctorValue += payment.doctorValue;
      existingWeek.payments.push(payment);
    }
  });

  // Sort the array by descending startDate
  groupedPayments.sort(
    (a: any, b: any) =>
      (new Date(b.startDate) as any) - (new Date(a.startDate) as any)
  );

  return groupedPayments;
}

// Helper function to get the start date of the week
function getWeekStartDate(year: any, weekNumber: any) {
  return moment()
    .year(year)
    .isoWeek(weekNumber)
    .startOf("isoWeek")
    .format("DD/MM/YYYY");
}

// Helper function to get the end date of the week
function getWeekEndDate(year: any, weekNumber: any) {
  return moment()
    .year(year)
    .isoWeek(weekNumber)
    .endOf("isoWeek")
    .format("DD/MM/YYYY");
}

export {
  filtersMobileInputs,
  filtersDesktopInputs,
  tableHeaders,
  groupByWeekAndSum
};

import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";

type TextFieldComponentProps = {
  label: string;
  value: string;
  onChange: (value: string) => void;
};
export default function TextFieldComponent({
  label,
  onChange,
  value
}: TextFieldComponentProps) {
  const [valueInternal, setValue] = useState<string>(value);

  const handleOnChange = (event: any) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (!value) setValue("");
  }, [value]);

  useEffect(() => {
    if (!valueInternal) return onChange("");
    const timeoutId = setTimeout(() => onChange(valueInternal), 500);
    return () => clearTimeout(timeoutId);
  }, [valueInternal]);

  return (
    <TextField
      label={label}
      variant="outlined"
      value={valueInternal}
      onChange={handleOnChange}
    />
  );
}

import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { useCallback, useEffect, useState } from "react";

export default function SliderComponent({
  title,
  onChange,
  value,
  minDistance = 50,
  ...props
}: any) {
  const [value1, setValue1] = useState<any>([0, 0]);

  useEffect(() => {
    setValue1(value);
  }, [value]);

  const handleChange = useCallback(
    (event: Event, newValue: number | number[], activeThumb: number) => {
      if (!Array.isArray(newValue)) {
        setValue1(newValue);
        return;
      }

      if (newValue[1] - newValue[0] < minDistance) {
        if (activeThumb === 0) {
          const clamped = Math.min(newValue[0], 1000 - minDistance);
          setValue1([clamped, clamped + minDistance]);
        } else {
          const clamped = Math.max(newValue[1], minDistance);
          setValue1([clamped - minDistance, clamped]);
        }
      } else {
        setValue1(newValue as number[]);
      }
    },
    []
  );

  return (
    <div>
      <Typography gutterBottom>{title}</Typography>

      <Slider
        valueLabelDisplay="auto"
        defaultValue={100}
        disableSwap
        value={value1}
        min={0}
        max={300}
        onChange={handleChange}
        onChangeCommitted={() => onChange(value1)}
        sx={{
          padding: "9px 0!important",
          height: 8,
          "& .MuiSlider-track": {
            border: "none"
          },
          "& .MuiSlider-thumb": {
            height: 24,
            width: 24,
            backgroundColor: "primary.contrastText",
            border: "2px solid currentColor",
            "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
              boxShadow: "inherit"
            },
            "&:before": {
              display: "none"
            }
          },
          "& .MuiSlider-valueLabel": {
            lineHeight: 1.2,
            fontSize: 12,
            background: "unset",
            padding: 0,
            width: 32,
            height: 32,
            borderRadius: "50% 50% 50% 0",
            backgroundColor: "secondary.main",
            transformOrigin: "bottom left",
            transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
            "&:before": { display: "none" },
            "&.MuiSlider-valueLabelOpen": {
              transform: "translate(50%, -100%) rotate(-45deg) scale(1)"
            },
            "& > *": {
              transform: "rotate(45deg)"
            }
          }
        }}
        {...props}
      />
    </div>
  );
}

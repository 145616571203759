import { TableCell } from "@mui/material";
import TableComponent, { StyledTableRow } from "../../components/Table/Table";
import { groupByWeekAndSum } from "../CommissionsHistory/utils";

export default function TableLastResult({ data }: any) {
  const dataToRender = groupByWeekAndSum(data)[0];
  return (
    <TableComponent
      headerCfg={[
        {
          label: "Data"
        },
        {
          label: "Valor"
        }
      ]}
    >
      <StyledTableRow>
        <TableCell align="center">
          {dataToRender.startDate} - {dataToRender.endDate}
        </TableCell>
        <TableCell align="center">
          {new Intl.NumberFormat("pt-PT", {
            style: "currency",
            currency: "EUR"
          }).format(dataToRender.totalDoctorValue)}
        </TableCell>
      </StyledTableRow>
    </TableComponent>
  );
}

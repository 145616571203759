import Chip from "@mui/material/Chip";

export default function ResetFiltersButton({ onClick }: any) {
  return (
    <Chip
      label="Restaurar Filtros"
      variant="outlined"
      onDelete={onClick}
      onClick={onClick}
    />
  );
}

import axios, { CancelToken } from "axios";

export const getPlans = async ({
  status,
  type
}: {
  status: "active" | "non-active";
  type: "consumable" | "subscription";
}) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_PAYMENTS_API_URL}/plan?status=${status}&type=${type}`
  );
  return data;
};

export const postPurchase = async ({
  email,
  plan,
  authToken
}: {
  email: string;
  plan: string;
  authToken: string;
}) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_PAYMENTS_API_URL}/purchase`,
    {
      skuId: plan,
      email
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }
  );
  return data;
};

export const getPurchaseService = async ({
  transactionId,
  authToken
}: {
  transactionId: string;
  authToken: string;
}) => {
  if (!authToken) return;

  const { data } = await axios.get(
    `${process.env.REACT_APP_PAYMENTS_API_URL}/purchase/${transactionId}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }
  );
  return data;
};

export const getPurchaseHistory = async ({
  authToken
}: {
  authToken: string;
}) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_PAYMENTS_API_URL}/purchase/history/user`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }
  );
  return data;
};

export const getCobrancasHistory = async ({
  authToken,
  accountId,
  page,
  dataIn,
  search,
  dataEnd,
  status,
  minPrice,
  maxPrice,
  sortBy,
  sortField,
  rowsPerPage
}: {
  authToken: string;
  accountId: string;
  page: number;
  dataIn?: string;
  dataEnd?: string;
  search?: string;
  status?: string;
  minPrice?: number;
  maxPrice?: number;
  sortBy?: string;
  sortField?: string;
  rowsPerPage: number;
}) => {
  const startAt = dataIn ? `&start_at=${dataIn}` : ``;
  const endAt = dataEnd ? `&end_at=${dataEnd}` : ``;
  const statusQuery = status ? `&status=${status}` : ``;
  const minPriceQuery = minPrice ? `&min_price=${minPrice}` : ``;
  const maxPriceQuery = maxPrice ? `&max_price=${maxPrice}` : ``;
  const sortByQuery = sortBy ? `&sort_dir=${sortBy}` : ``;
  const sortFieldQuery = sortField ? `&sort=${sortField}` : ``;
  const searchFieldQuery = search ? `&search=${search}` : ``;

  const { data } = await axios.get(
    `${process.env.REACT_APP_PAYMENTS_API_URL}/transaction?per_page=${rowsPerPage}&page=${page}&issuer=${accountId}${startAt}${endAt}${statusQuery}${minPriceQuery}${maxPriceQuery}${sortByQuery}${sortFieldQuery}${searchFieldQuery}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }
  );
  return data;
};

export const getPaidTransactionsHistory = async ({
  authToken,
  accountId,
  dataIn,
  dataEnd
}: {
  authToken: string;
  accountId: string;
  dataIn?: string;
  dataEnd?: string;
}) => {
  const startAt = dataIn ? `&start_at=${dataIn}` : ``;
  const endAt = dataEnd ? `&end_at=${dataEnd}` : ``;

  const { data } = await axios.get(
    `${process.env.REACT_APP_PAYMENTS_API_URL}/transaction?&issuer=${accountId}${startAt}${endAt}&status=paid&time_base=paid_at`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }
  );
  return data.items;
};

export const getDownloadFileCommissionsHistory = async ({
  authToken,
  dateIn,
  dateEnd
}: {
  authToken: string;
  dateIn?: string;
  dateEnd?: string;
}) => {
  const startAt = dateIn ? `&start_at=${dateIn}` : ``;
  const endAt = dateEnd ? `&data_end=${dateEnd}` : ``;

  const { data } = await axios.get(
    `${process.env.REACT_APP_PAYMENTS_API_URL}/transaction/file/commissions?${startAt}${endAt}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }
  );
  return data;
};

import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState
} from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import useParticipantsContext from "../../hooks/useParticipantsContext/useParticipantsContext";
import { useVideoState } from "..";

export interface TwilioContextType {
  createRoom: (token: string) => void;
  checkRoom: (room: any) => void;
  twilioToken: string;
  roomName: string;
  setRoomName: Dispatch<SetStateAction<string>>;
  setTwilioToken: Dispatch<SetStateAction<string>>;
  setNameUser: Dispatch<SetStateAction<string>>;
  nameUser: string;
  loading: boolean;
}

export const TwilioContext = createContext<TwilioContextType>(null!);

export default function TwilioProvider(props: PropsWithChildren<{}>) {
  const history = useHistory();
  const [twilioToken, setTwilioToken] = useState<string>("");
  const [roomName, setRoomName] = useState<string>("");
  const [nameUser, setNameUser] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { setIsOwner } = useVideoState();

  const createRoom = async (token: string) => {
    try {
      setLoading(true);
      setIsOwner(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/video`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setTwilioToken(data.token);
      setRoomName(data.roomName);
      setLoading(false);

      return data;
    } catch (error) {
      history.push("/notfound");
    }
  };

  const checkRoom = async (room: any) => {
    try {
      setLoading(true);
      const {
        data: { token }
      } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/video/${room}`
      );

      setTwilioToken(token);
      setRoomName(room);
      setLoading(false);
    } catch (error) {
      history.push("/notfound");
    }
  };

  return (
    <TwilioContext.Provider
      value={{
        checkRoom,
        createRoom,
        twilioToken,
        loading,
        roomName,
        setRoomName,
        setTwilioToken,
        setNameUser,
        nameUser
      }}
    >
      {props.children}
    </TwilioContext.Provider>
  );
}

export function useApiTwilio() {
  const context = useContext(TwilioContext);
  if (!context) {
    throw new Error("useApiTwilio must be used within the TwilioStateProvider");
  }
  return context;
}

import ReactDOM from "react-dom";
import { CssBaseline } from "@mui/material";
import CreateVideo from "./CreateVideo";
import JoinVideo from "./JoinVideo";
import VideoStateProvider, { useVideoState } from "./state";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ErrorDialog from "./components/video/ErrorDialog/ErrorDialog";
import PrivateRoute from "./components/video/PrivateRoute/PrivateRoute";
import theme from "./theme";
import "./types";
import { ChatProvider } from "./components/video/ChatProvider";
import { ParticipantProvider } from "./components/video/ParticipantProvider";
import { VideoProvider } from "./components/video/VideoProvider";
import useConnectionOptions from "./utils/useConnectionOptions/useConnectionOptions";
import UnsupportedBrowserWarning from "./components/video/UnsupportedBrowserWarning/UnsupportedBrowserWarning";
import Auth0ProviderWithHistory from "./state/auth0-provider-with-history";
import TwilioProvider from "./state/useApiTwilio/twilio-provider";
import Disconnected from "./Disconnected";
import Navbar from "./components/navbar/Navbar";
import Home from "./Home";
import { SnackbarProvider } from "notistack";
import PaymentsHistory from "./PaymentsHistory";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/Loading/Loading";
import { Box, ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import "react-big-calendar/lib/css/react-big-calendar.css";
import UserStateProvider, { useUserState } from "./state/user";
import ChargesHistory from "./pages/ChargesHistory/ChargesHistory";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "moment/locale/pt";
import "./global.scss";
import Plans from "./components/plans";
import FiltersStateProvider from "./state/filters";
import CommissionsHistory from "./pages/CommissionsHistory/CommissionsHistory";
import RootCMDS from "./pages/CMDS";
import PaymentsAccount from "./pages/PaymentsAccount";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const CreateVideoApp = () => {
  const { error, setError } = useVideoState();
  const connectionOptions = useConnectionOptions();
  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ParticipantProvider>
        <ChatProvider>
          <CreateVideo />
        </ChatProvider>
      </ParticipantProvider>
    </VideoProvider>
  );
};

const JoinVideoApp = () => {
  const { error, setError } = useVideoState();
  const connectionOptions = useConnectionOptions();
  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ParticipantProvider>
        <ChatProvider>
          <JoinVideo />
        </ChatProvider>
      </ParticipantProvider>
    </VideoProvider>
  );
};

const RootApp = () => {
  const { isLoading, isAuthenticated } = useAuth0();
  const { user, loading } = useUserState();

  if (isLoading || loading || (isAuthenticated && !user)) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Loading />
      </Box>
    );
  }
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt">
      <Navbar />
      <Switch>
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute path="/charges-history" component={ChargesHistory} />
        <PrivateRoute
          path="/commissions-history"
          component={CommissionsHistory}
        />
        <PrivateRoute
          path="/payments-account/:type"
          component={PaymentsAccount as any}
        />
        <PrivateRoute path="/cmds/:type" component={RootCMDS as any} />
        <UnsupportedBrowserWarning>
          <VideoStateProvider>
            <TwilioProvider>
              <PrivateRoute path="/video" component={CreateVideoApp} />
              <Route path="/app/state/disconnected" component={Disconnected} />
              <Route path="/notfound">
                <div>NOT FOUND</div>
              </Route>
              <Route
                path="/invite-video/:URLRoomName?/:URLParticipantName"
                component={JoinVideoApp}
              />
            </TwilioProvider>
          </VideoStateProvider>
        </UnsupportedBrowserWarning>
      </Switch>
    </LocalizationProvider>
  );
};

export const ReactApp = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Auth0ProviderWithHistory>
            <UserStateProvider>
              <FiltersStateProvider>
                <RootApp />
              </FiltersStateProvider>
            </UserStateProvider>
          </Auth0ProviderWithHistory>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ReactDOM.render(<ReactApp />, document.getElementById("root"));

import { Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px!important"
    },
    [theme.breakpoints.up("xl")]: {
      marginBottom: "100px!important"
    }
  },
  subtitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px!important"
    }
  },
  container: {
    paddingTop: "40px",
    paddingBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "25px",
      paddingBottom: "15px"
    }
  }
}));

export default function HeaderTitle({ children }: any) {
  const classes = useStyles();

  return (
    <Typography
      component="h1"
      variant="h2"
      align="center"
      color="text.primary"
      gutterBottom
      className={classes.title}
      fontWeight={400}
      sx={{
        marginBottom: { xs: 2, sm: 6 }
      }}
    >
      {children}
    </Typography>
  );
}

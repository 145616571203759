const tableHeaders = [
  {
    label: "Período de Faturação"
  },
  {
    label: "Valor"
  }
];
const filtersMobileInputs = [
  {
    id: "dates",
    size: {
      xs: 12,
      sm: 6
    },
    component: "dateRange",
    props: {
      titles: ["Data Inicio", "Data Fim"]
    }
  }
];

const filtersDesktopInputs = [
  {
    id: "dates",
    size: {
      sm: 4,
      md: 4,
      lg: 3
    },
    component: "dateRange",
    props: {
      titles: ["Data Inicio", "Data Fim"]
    }
  }
];

export { tableHeaders, filtersDesktopInputs, filtersMobileInputs };

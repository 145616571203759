import "./style.scss";

export default function Success() {
  return (
    <div>
      <div className="circle-border"></div>
      <div className="circle">
        <div className="error"></div>
      </div>
    </div>
  );
}

import axios from "axios";

export const fetchUser = async (token: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/professionals`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return data.professional;
};

export const fetchFichasClinicas = async (token: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/professionals/clinicalFilesAccesses`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return data;
};

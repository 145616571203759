import { useEffect, useState } from "react";
import { getPurchaseService } from "../services/payments";
import { useUserState } from "../state/user";

export default function usePurchase({
  transactionId
}: {
  transactionId: string | null;
}) {
  const [data, setData] = useState<{
    id: string;
    skuId: string;
    userId: string;
    createdAt: Date;
    planName: string;
  } | null>(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token } = useUserState();

  useEffect(() => {
    (async function() {
      try {
        if (!transactionId) return;
        setLoading(true);
        const result = await getPurchaseService({
          transactionId,
          authToken: token
        });
        setData(result);
      } catch (err) {
        setError(err as any);
      } finally {
        setLoading(false);
      }
    })();
  }, [token, transactionId]);

  return { data, error, loading };
}

import React from "react";
import { styled, Theme } from "@mui/material/styles";

import MenuBar from "./components/video/MenuBar/MenuBar";
import MobileTopMenuBar from "./components/video/MobileTopMenuBar/MobileTopMenuBar";
import PreJoinScreens from "./components/video/PreJoinScreens/PreJoinScreens";
import ReconnectingNotification from "./components/video/ReconnectingNotification/ReconnectingNotification";
import RecordingNotifications from "./components/video/RecordingNotifications/RecordingNotifications";
import Room from "./components/video/Room/Room";

import useHeight from "./hooks/useHeight/useHeight";
import useRoomState from "./hooks/useRoomState/useRoomState";
import { Redirect } from "react-router-dom";
import { useVideoState } from "./state";

const Container = styled("div")({
  display: "grid",
  gridTemplateRows: "1fr auto"
});

const Main = styled("main")(({ theme }: { theme: Theme }) => ({
  overflow: "hidden",
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: "black",
  [theme.breakpoints.down("md")]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px` // Leave some space for the mobile header and footer
  }
}));

export default function CreateVideo() {
  const roomState = useRoomState();
  const { endRoom } = useVideoState();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  if (roomState === "disconnected" && endRoom) {
    return <Redirect to="/app/state/disconnected" />;
  }

  return (
    <Container style={{ height }}>
      {roomState === "disconnected" && !endRoom ? (
        <PreJoinScreens />
      ) : (
        <Main>
          <ReconnectingNotification />
          <RecordingNotifications />
          <MobileTopMenuBar />
          <Room />
          <MenuBar />
        </Main>
      )}
    </Container>
  );
}

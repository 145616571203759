import { Button } from "@mui/material";
import { Typography } from "@mui/material";
import Loading from "../Loading/Loading";

export default function ButtonComponent({
  onClick,
  label,
  buttonStyles,
  disabled = false,
  selected = false
}: any) {
  return (
    <Button
      style={{
        borderRadius: "25px",
        cursor: "pointer",
        ...(selected && { backgroundColor: "#16BA81" }),
        ...buttonStyles
      }}
      variant={"contained"}
      onClick={onClick}
      disabled={disabled}
    >
      {selected && disabled ? (
        <Loading width={24} height={24} color={"white"} />
      ) : (
        <Typography color="white">
          <b>{label}</b>
        </Typography>
      )}
    </Button>
  );
}

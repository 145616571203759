import { Box, TableCell, TableRow, Typography } from "@mui/material";
import usePurchaseHistory from "./hooks/usePurchaseHistory";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ContainerWrapper from "./components/ContainerWrapper/ContainerWrapper";
import HeaderTitle from "./components/HeaderTitle/HeaderTitle";
import Loading from "./components/Loading/Loading";
import Status from "./components/Status/Status";
import { formatDate } from "./utils/date";
import DownloadButton from "./components/DownloadButton/DownloadButton";
import TableComponent, { StyledTableRow } from "./components/Table/Table";

const tableHeaders = [
  {
    label: "Data"
  },
  {
    label: "Nome Plano"
  },
  {
    label: "Valor"
  },
  {
    label: "Estado"
  },

  {
    label: "Recibo"
  }
];

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    fontSize: "16px!important",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px!important"
    }
  }
}));

export default function PaymentsHistory() {
  const { data, loading, error } = usePurchaseHistory();
  const classes = useStyles();

  return (
    <ContainerWrapper>
      <HeaderTitle>Histórico de Compras - CMD</HeaderTitle>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={5}
        >
          <Loading />
        </Box>
      ) : !!(data as any)?.length ? (
        <TableComponent headerCfg={tableHeaders}>
          {(data as any).map((row: any) => (
            <StyledTableRow key={row.id}>
              <TableCell
                className={classes.tableRow}
                align="center"
                width="20%"
                style={{ whiteSpace: "nowrap" }}
              >
                {formatDate(row.createdAt)}
              </TableCell>
              <TableCell
                className={classes.tableRow}
                align="center"
                width="20%"
              >
                {row.planName}
              </TableCell>
              <TableCell
                className={classes.tableRow}
                align="center"
                width="20%"
              >
                {row.transaction.value}€
              </TableCell>
              <TableCell
                className={classes.tableRow}
                align="center"
                width="20%"
              >
                <Status
                  urlPending={row.transaction.urlPending}
                  status={row.transaction.status}
                />
              </TableCell>

              <TableCell className={classes.tableRow} align="center" width="5%">
                {row.transaction.status === "paid" && (
                  <DownloadButton url={row.transaction.urlDocument} />
                )}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableComponent>
      ) : (
        <>
          <br />
          <Typography>Não foram encontrados resultados.</Typography>
        </>
      )}
    </ContainerWrapper>
  );
}

import React from "react";
import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import Button from "@mui/material/Button";
import ScreenShareIcon from "../../../../icons/ScreenShareIcon";
import Tooltip from "@mui/material/Tooltip";

import useScreenShareParticipant from "../../../../hooks/useScreenShareParticipant/useScreenShareParticipant";
import useVideoContext from "../../../../hooks/useVideoContext/useVideoContext";

export const SCREEN_SHARE_TEXT = "Partilhar ecrã";
export const STOP_SCREEN_SHARE_TEXT = "Parar partilha de ecrã";
export const SHARE_IN_PROGRESS_TEXT =
  "Não é possivel partilhar quando outro usuário se encontra a partilhar";
export const SHARE_NOT_SUPPORTED_TEXT =
  "Partilha de ecrã não é suportada neste browser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      "&[disabled]": {
        color: "#bbb",
        "& svg *": {
          fill: "#bbb"
        }
      }
    }
  })
);

export default function ToggleScreenShareButton(props: {
  disabled?: boolean;
  labelStyle?: string;
}) {
  const classes = useStyles();
  const screenShareParticipant = useScreenShareParticipant();
  const { toggleScreenShare } = useVideoContext();
  const disableScreenShareButton = Boolean(screenShareParticipant);
  const isScreenShareSupported =
    navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled =
    props.disabled || disableScreenShareButton || !isScreenShareSupported;

  let tooltipMessage = "";

  if (disableScreenShareButton) {
    tooltipMessage = SHARE_IN_PROGRESS_TEXT;
  }

  if (!isScreenShareSupported) {
    tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
  }

  return (
    <Tooltip
      title={tooltipMessage}
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
    >
      <span>
        {/* The span element is needed because a disabled button will not emit hover events and we want to display
          a tooltip when screen sharing is disabled */}
        <Button
          className={classes.button}
          onClick={toggleScreenShare}
          disabled={isDisabled}
          startIcon={<ScreenShareIcon />}
          data-cy-share-screen
        >
          <span className={props.labelStyle}>{SCREEN_SHARE_TEXT}</span>
        </Button>
      </span>
    </Tooltip>
  );
}

import Box from "@mui/material/Box";
import { BarChart } from "@mui/x-charts/BarChart";
import { AxisConfig, LineChart } from "@mui/x-charts";
import { formatData } from "./utils";

export default function GraphCommissionsHistory({ dataResults }: any) {
  const data = formatData(
    dataResults.sort((a: any, b: any) => {
      return new Date(a.paid_at).getTime() - new Date(b.paid_at).getTime();
    })
  );

  if (data && Object.keys(data).length === 1) return <></>;

  const values =
    data &&
    Object.keys(data).map((item: any) => {
      return data[item].value;
    });

  const xLabels =
    data &&
    Object.keys(data).map((item: any) => {
      return item;
    });
  const currencyFormatter = new Intl.NumberFormat("pt-PT", {
    style: "currency",
    currency: "EUR"
  }).format;

  return (
    <Box
      sx={{
        width: "100%",
        height: "500px",
        justifyContent: "center",
        display: "flex",
        alignItems: "center"
      }}
      className="custom-y-padding-bottom"
    >
      <LineChart
        xAxis={[
          {
            data: xLabels,
            scaleType: "band",
            label: "Data",
            labelStyle: {
              fontSize: 15,
              lineHeight: 30
            }
          } as Omit<AxisConfig, "id">
        ]}
        yAxis={[
          {
            max: Math.max(...values) * 1.1,
            min: 0,
            valueFormatter: value => {
              if (value === 0) return currencyFormatter(value);

              return value;
            },
            label: "Valor (€)",
            labelStyle: {
              fontSize: 15,
              lineHeight: 30
            }
          }
        ]}
        series={[
          {
            data: values,
            valueFormatter: currencyFormatter
          }
        ]}
        sx={{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "inherit" // Or 'transparent' or whatever color you'd like
          }
        }}
        axisHighlight={{ x: "none", y: "none" }}
      />
    </Box>
  );
}

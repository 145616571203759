import { Box, Typography } from "@mui/material";
import Loading from "../Loading/Loading";

export default function AsyncResultsWrapper({ data, loading, children }: any) {
  return loading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginTop={5}
    >
      <Loading />
    </Box>
  ) : data?.length ? (
    children
  ) : (
    <Typography>Não foram encontrados resultados.</Typography>
  );
}

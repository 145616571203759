import {
  Box,
  ListItemText,
  ListItemIcon,
  Avatar,
  MenuItem,
  MenuList,
  Divider
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import MenuItemComponent from "./MenuItem";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
export default function NavListDrawer({
  navLinks,
  navLinksBottom,
  onClose,
  user
}: any) {
  return (
    <Box sx={{ width: 320, marginTop: 5 }}>
      <MenuOpenIcon
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          margin: "20px",
          fontSize: "45px",
          opacity: 0.7,
          color: "#000000DE"
        }}
      />
      <Avatar
        alt={user?.personalInfo?.givenName}
        src={user?.personalInfo?.picture}
        sx={{
          width: 100,
          height: 100,
          margin: "auto"
        }}
      />
      <Divider sx={{ marginTop: "20px" }} />

      <nav>
        <MenuList>
          {navLinks.map((item: any) => (
            <MenuItemComponent key={item.title} item={item} onClick={onClose} />
          ))}
          <Divider />
          {navLinksBottom.map((item: any) => (
            <MenuItemComponent key={item.title} item={item} onClick={onClose} />
          ))}
        </MenuList>
      </nav>
    </Box>
  );
}

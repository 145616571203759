export const colors = {
  standardBackgroundColor: "#f2f2f2",
  tintColor: "#007aff",
  warningBackground: "#EAEB5E",
  dangerColor: "#dc2f3e",
  backgroundColor: "#fff",
  primaryColor: "#74b09e",
  primaryFadedColor: "#c6ded7",
  toolBarGreen: "#a6cbc0",
  secondaryColor: "#325291",
  textColor: "#000000",
  fadedTextColor: "#5f5f5f",
  borderColor: "#b7b7b7",
  borderColorLight: "#dddddd",
  attentionColor: "#E27D60",
  badgeColor: "#7c9cca",
  inputBoxBorderColor: "#e7e8e9",
  inputBoxBackgroundColor: "#f8f9fb",
  shadowColor: "#000000",
  diagnosticsColor: "#319dd4",
  darkCyan: "#03a898",
  lightGreenBorder: "#88D498",
  darkGreenBorder: "#1A936F",
  sectionTitleGrey: "#8e8e93",
  navBarGreen: "#03C1AF",
  get validFormColor() {
    return this.navBarGreen;
  },
  invalidFormColor: "#FFB405",
  messagesButtonColor: "#00d466",
  prescribeButtonColor: "#109AA1",
  historyButtonColor: "#2C6B6E",
  treatmentGuidesButtonColor: "#D9364E",
  diagnosticsButtonColor: "#00BCED",
  backgroundButtonColor: "#32409A",
  allergiesButtonColor: "#ed7200",
  intolerancesButtonColor: "#ffad6c",
  medicationButtonColor: "#13BA80",
  prescribeExamBackgroundColor: "#7E5EDB",
  historyExamBackgroundColor: "#8B2BE2",
  statusBar: "#cccccc",
  medicationAllergyBackgroundColor: "#e27d60",
  intolerancesBackgroundColor: "#d0775d",
  otherAllergyBackgroundColor: "#c24223",
  searchBackground: "#e6f1ee",
  floatingActionButtonColor: "rgba(231,76,60,1)",
  guideEntriesBackgroundColor: "rgba(198,222,215,.5)",
  white: "white",
  grey: "grey",
  lightgrey: "lightgrey",
  lightergrey: "#EEEEEE",
  black: "black",
  headerButtonColor: "#ffffff",
  fadedWhite: "#ffffff85",
  noActive: "#028578",
  diagnosticsCardBackground: "#00BCED",
  allergiesCardBackground: "#ed7200",
  medicationCardBackground: "#13BA80",
  monitoringCardBackground: "#D9364E",
  examsAndReportsCardBackground: "#36a0d9",
  messagesCardBackground: "#00d466",
  historyCardBackground: "#109AA1",
  treatmentGuidesCardBackground: "#F2BD14",
  lightNavBarGreen: "#03dac6",
  mediumBlackText: "#242424",
  lightBlackText: "#585858",
  darkGrey: "#373737",
  softGrey: "#8C9794",
  prevColor: "#707070",
  softLightGrey: "#D3D3D3",
  softOrange: "#fff4ec",
  greyIcon: "#707070",
  lavander: "#B57EDC",
  yellowLightBackground: "#FFFFF0",
  switchAndroidTrackColor: "#d3d3d3",
  monthly: "#2474EB",
  semester: "#00BCED",
  biannual: "#00BCED",
  yearly: "#00D466",
  free: "#989898",
  subscriptions: {
    basic: "#CAEA18",
    standard: "#FFD800",
    advanced: "#F2BD14",
    premium: "#D8AE00",
    free: "#BAC0C6",
    plus: "#45E079",
    pro: "#3CC369",
    best: "#319B55",
    family: "#216E3B",
    starter: "#B9D5ED",
    plusProfessional: "#5A97B3",
    proProfessional: "#474DA1",
    P1M: "#2474EB",
    P6M: "#00BCED",
    P1Y: "#00D466"
  },
  registries: "#f9b715",
  videoCall: "#1952D7",
  archives: "#92979f",
  allDocuments: "#b6b6b4"
};

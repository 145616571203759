import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import MenuContainer from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreIcon from "@mui/icons-material/MoreVert";
import Typography from "@mui/material/Typography";
import { Input, Theme, useMediaQuery } from "@mui/material";

import AboutDialog from "../../../AboutDialog/AboutDialog";
import ConnectionOptionsDialog from "../../../ConnectionOptionsDialog/ConnectionOptionsDialog";
import DeviceSelectionDialog from "../../../DeviceSelectionDialog/DeviceSelectionDialog";
import SettingsIcon from "../../../../../icons/SettingsIcon";
import { useVideoState } from "../../../../../state";
import DialogComponent from "../../../../Dialog/Dialog";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";

export default function SettingsMenu({
  mobileButtonClass,
  roomName
}: {
  mobileButtonClass?: string;
  roomName: string;
}) {
  const { roomType } = useVideoState();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [menuOpen, setMenuOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);
  const [connectionSettingsOpen, setConnectionSettingsOpen] = useState(false);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [inviteOpenName, setInviteOpenName] = useState("");

  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      {isMobile ? (
        <Button
          ref={anchorRef}
          onClick={() => setMenuOpen(true)}
          startIcon={<MoreIcon />}
          className={mobileButtonClass}
        >
          Mais
        </Button>
      ) : (
        <Button
          ref={anchorRef}
          onClick={() => setMenuOpen(true)}
          startIcon={<SettingsIcon />}
        >
          Definições
        </Button>
      )}
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: isMobile ? "left" : "right"
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: "center"
        }}
      >
        <MenuItem onClick={() => setInviteOpen(true)}>
          <Typography variant="body1">Convidar</Typography>
        </MenuItem>
        <MenuItem onClick={() => setAboutOpen(true)}>
          <Typography variant="body1">Sobre</Typography>
        </MenuItem>
        <MenuItem onClick={() => setDeviceSettingsOpen(true)}>
          <Typography variant="body1">Definições de Audio e Vídeo</Typography>
        </MenuItem>
        {roomType !== "peer-to-peer" && roomType !== "go" && (
          <MenuItem onClick={() => setConnectionSettingsOpen(true)}>
            <Typography variant="body1">Definições de conexão</Typography>
          </MenuItem>
        )}
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        onClose={() => {
          setDeviceSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
      <ConnectionOptionsDialog
        open={connectionSettingsOpen}
        onClose={() => {
          setConnectionSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
      <DialogComponent
        open={inviteOpen}
        handleModal={state => setInviteOpen(state)}
      >
        <DialogTitle>{"Selecione o nome do convidado"}</DialogTitle>
        <DialogContent>
          <Input
            value={inviteOpenName}
            onChange={text => setInviteOpenName(text.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant={"contained"}
            onClick={() => {
              navigator.clipboard.writeText(
                `${
                  process.env.REACT_APP_DOMAIN
                }/invite-video/${roomName}/${inviteOpenName || "Convidado"}`
              );
              setInviteOpen(false);
            }}
          >
            Copiar Link
          </Button>
        </DialogActions>
      </DialogComponent>
    </>
  );
}

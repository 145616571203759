import { Chip, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { colors } from "../../utils/colors";
import ReplayIcon from "@mui/icons-material/Replay";

const mapStatus: any = {
  pending: {
    color: colors.invalidFormColor,
    text: "Pendente"
  },
  payment_pending: {
    color: colors.invalidFormColor,
    text: "Aguarda Processamento"
  },
  paid: {
    color: colors.navBarGreen,
    text: "Pago"
  },
  expired: {
    color: colors.dangerColor,
    text: "Expirado"
  },
  payment_error: {
    color: colors.dangerColor,
    text: "Erro"
  }
};

export default function Status({ urlPending = null, status }: any) {
  return (
    <Chip
      sx={{
        cursor: urlPending ? "pointer" : "unset",
        width: "241px",
        color: "white",
        fontSize: "18px",
        fontWeight: "bold",
        backgroundColor: mapStatus[status].color
      }}
      label={mapStatus[status].text}
      clickable={false}
      onClick={() => {
        if (!urlPending) return null;
        window.location.assign(urlPending);
      }}
      icon={urlPending && <ReplayIcon color="inherit" />}
    />
  );
}

import React, { useState, useEffect } from "react";
import DeviceSelectionScreen from "./DeviceSelectionScreen/DeviceSelectionScreen";
import IntroContainer from "../IntroContainer/IntroContainer";
import MediaErrorSnackbar from "./MediaErrorSnackbar/MediaErrorSnackbar";
import { useParams } from "react-router-dom";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { useApiTwilio } from "../../../state/useApiTwilio/twilio-provider";
import { useUserState } from "../../../state/user";

export default function PreJoinScreens() {
  const { checkRoom, loading, setNameUser, createRoom } = useApiTwilio();
  const { token } = useUserState();

  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName, URLParticipantName } = useParams<{
    URLRoomName?: string;
    URLParticipantName?: string;
  }>();
  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (!URLParticipantName && !URLRoomName && token) {
      createRoom(token);
    }
  }, [URLParticipantName, URLRoomName, token]);

  useEffect(() => {
    if (URLParticipantName) {
      setNameUser(URLParticipantName);
    }
    console.log(URLRoomName, "URLRoomName");
    if (URLRoomName) {
      checkRoom(URLRoomName);
    }
  }, [URLParticipantName, URLRoomName]);

  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log("Error acquiring local media:");
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, mediaError]);

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      <DeviceSelectionScreen />
    </IntroContainer>
  );
}

import { useEffect, useState } from "react";
import { getCobrancasHistory } from "../services/payments";
import { useUserState } from "../state/user";
import axios from "axios";

export default function useCobrancasHistory({
  page,
  rowsPerPage,
  filters,
  sort
}: any) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token, user, loading: loadingUser } = useUserState();
  useEffect(() => {
    (async function() {
      try {
        if (loadingUser) return;
        setLoading(true);
        const result = await getCobrancasHistory({
          authToken: token,
          ...(filters.dates[0] && { dataIn: filters.dates[0].toISOString() }),
          ...(filters.dates[1] && { dataEnd: filters.dates[1].toISOString() }),
          ...(filters.searchTxt && { search: filters.searchTxt }),
          ...(filters.status && { status: filters.status }),
          ...(filters.prices[0] && { minPrice: filters.prices[0] }),
          ...(filters.prices[1] && { maxPrice: filters.prices[1] }),
          ...(sort.field && { sortField: sort.field }),
          ...(sort.by && { sortBy: sort.by }),
          accountId: user.accountStatus.paymentId,
          page,
          rowsPerPage
        });
        setData(result);
      } catch (err) {
        if (!axios.isCancel(error)) {
          return;
        }

        setError(err as any);
      } finally {
        setLoading(false);
      }
    })();
  }, [
    error,
    filters.searchTxt,
    filters.dates,
    filters.prices,
    filters.status,
    loadingUser,
    page,
    rowsPerPage,
    sort.by,
    sort.field,
    token,
    user.accountStatus.paymentId
  ]);

  return { data, error, loading };
}

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableCellPropsSizeOverrides,
  TableContainer,
  TableHead,
  TableRow,
  TableRowTypeMap,
  tableCellClasses
} from "@mui/material";
import TablePaginationComponent from "../TablePagination/TablePagination";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { styled } from "@mui/material/styles";
import { OverridableStringUnion } from "@mui/types";

type TableComponentProps = {
  headerCfg: {
    label: string;
    align?: "inherit" | "left" | "center" | "right" | "justify";
    size?: OverridableStringUnion<
      "small" | "medium",
      TableCellPropsSizeOverrides
    >;
  }[];
  children: any;
  page?: number;
  rowsPerPage?: number;
  total?: number;
  onChangePagination?: (type: "rowsPerPage" | "page", value: number) => void;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  },
  fontWeight: "bold!important" as any,
  [theme.breakpoints.up("md")]: {
    fontSize: "20px!important"
  }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#e0e0e052"
  },
  border: "1px solid #e0e0e052",
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

export default function TableComponent({
  headerCfg,
  children,
  page,
  rowsPerPage,
  total,
  onChangePagination
}: TableComponentProps) {
  const havePagination = page && rowsPerPage && total && onChangePagination;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headerCfg.map((cfg, i) => (
                  <StyledTableCell
                    key={`${cfg.label}-${i}`}
                    align={cfg?.align || "center"}
                    size={cfg?.size || "medium"}
                  >
                    {cfg.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{children}</TableBody>
          </Table>
        </TableContainer>
      </Box>
      {havePagination ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            marginTop: "20px"
          }}
        >
          <TablePaginationComponent
            count={total}
            page={page}
            onPageChange={(value: any) => {
              onChangePagination("page", value);
            }}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(value: any) => {
              onChangePagination("rowsPerPage", value);
            }}
          />
        </Box>
      ) : null}
    </>
  );
}

import TabsComponent from "../../components/Tabs/Tabs";
import BillingHistory from "../BillingHistory/BillingHistory";
import ChargesHistory from "../ChargesHistory/ChargesHistory";
import CommissionsHistory from "../CommissionsHistory/CommissionsHistory";
import Resume from "./Resume";

const routes = ["resume", "payments_history", "billing", "commissions"];

export default function PaymentsAccount(props: any) {
  const checkType = (type: any) => {
    const index = routes.indexOf(type);

    return index;
  };

  const currentTab = checkType(props.match.params.type);

  return (
    <TabsComponent
      value={currentTab}
      tabs={[
        {
          label: "Resumo",
          contentBody: <Resume />
        },
        {
          label: "Histórico de Pagamentos",
          contentBody: <ChargesHistory />
        },
        {
          label: "Histórico de Transferências",
          contentBody: <BillingHistory />
        },
        {
          label: "Histórico de Comissões",
          contentBody: <CommissionsHistory />
        }
      ]}
      handleChange={e => props.history.push(`/payments-account/${routes[e]}`)}
    />
  );
}

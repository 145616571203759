import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import EndCallButton from "../Buttons/EndCallButton/EndCallButton";
import Menu from "../MenuBar/Menu/Menu";
import useParticipantsContext from "../../../hooks/useParticipantsContext/useParticipantsContext";
import { useVideoState } from "../../../state";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: "white",
    paddingLeft: "1em",
    display: "none",
    height: `${theme.mobileTopBarHeight}px`,
    [theme.breakpoints.down("md")]: {
      display: "flex"
    }
  },
  endCallButton: {
    height: "28px",
    fontSize: "0.85rem",
    padding: "0 0.6em"
  },
  settingsButton: {
    [theme.breakpoints.down("md")]: {
      height: "28px",
      minWidth: "28px",
      border: "1px solid rgb(136, 140, 142)",
      padding: 0,
      margin: "0 1em"
    }
  },
  subtitle: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  }
}));

export default function MobileTopMenuBar() {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { isOwner } = useVideoState();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className={classes.container}
    >
      <Typography className={classes.subtitle} variant="subtitle1">
        {room!.name}
      </Typography>
      <div>
        {isOwner && <EndCallButton className={classes.endCallButton} />}
        <Menu buttonClassName={classes.settingsButton} />
      </div>
    </Grid>
  );
}

import { Box, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useQuery } from "../../hooks/useQuery";
import DialogComponent from "../Dialog/Dialog";
import { useEffect, useState } from "react";
import Error from "../Error";
import usePurchase from "../../hooks/usePurchase";
import Loading from "../Loading/Loading";
import Lottie from "lottie-react";
import SuccessLottie from "./success.json";
import { useHistory } from "react-router-dom";
import Button from "../Button/Button";

export default function DialogEndPayment() {
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const query = useQuery();
  const callbackQuery = query.get("callback");
  const methodQuery = query.get("method");
  const transactionId = query.get("transactionId");
  const { data, loading, error } = usePurchase({
    transactionId
  });

  useEffect(() => {
    if (!open) {
      const queryParams = "";
      history.replace({
        search: queryParams
      });
    }
  }, [history, open]);

  const successQuery = callbackQuery === "success";

  if (!callbackQuery && !transactionId) return <></>;
  const map: any = {
    mbw:
      "A sua compra foi concluída com sucesso. Enviaremos a fatura-recibo por e-mail. Obrigado.",
    cc:
      "A sua compra foi concluída com sucesso. Enviaremos a fatura-recibo por e-mail. Obrigado.",
    mb:
      "A sua compra está a aguardar pagamento através da Referência Multibanco gerada. Depois de concluída a transação, receberá a fatura-recibo por e-mail."
  };

  return (
    <DialogComponent
      open={open}
      handleModal={setOpen}
      headerTxt="Aquisição de CMD"
    >
      <DialogTitle>
        {successQuery ? map[methodQuery as string] : "Erro a efetuar a compra."}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={"135px"}
              sx={{
                margin: "0 auto"
              }}
            >
              {successQuery ? (
                <Lottie animationData={SuccessLottie} />
              ) : (
                <Error />
              )}
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                component="h2"
                variant="h6"
                color="text.primary"
                fontWeight="bold"
              >
                {data?.planName}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop={2}
            >
              <Button
                onClick={() => setOpen(false)}
                label="Sair"
                buttonStyles={{
                  width: "35%"
                }}
              />
            </Box>
          </>
        )}
      </DialogContent>
    </DialogComponent>
  );
}

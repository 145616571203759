import { TableCell, Theme } from "@mui/material";
import TableComponent, { StyledTableRow } from "../../components/Table/Table";
import { formatDate } from "../../utils/date";
import DownloadButton from "../../components/DownloadButton/DownloadButton";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    fontSize: "16px!important",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px!important"
    }
  }
}));
export default function TableLastsResults({ inputProps }: any) {
  const classes = useStyles();
  let results = inputProps
    .sort((a: any, b: any) => {
      return new Date(a.paid_at).getTime() - new Date(b.paid_at).getTime();
    })
    .reverse()
    .slice(0, 5);

  return (
    <TableComponent
      headerCfg={[
        {
          label: "Data do Pedido"
        },
        {
          label: "Data do Pagamento"
        },
        {
          label: "Nome do Utente",
          align: "left"
        },
        {
          label: "Fatura-Recibo"
        },
        {
          label: "Valor"
        }
      ]}
    >
      {(results as any).map((row: any) => (
        <StyledTableRow
          key={row.id}
          sx={{
            "& > *": { borderBottom: "unset" },
            cursor: "pointer"
          }}
        >
          <TableCell className={classes.tableRow} align="center">
            {formatDate(row.created_at)}
          </TableCell>
          <TableCell className={classes.tableRow} align="center">
            {row.paid_at ? formatDate(row.paid_at) : null}
          </TableCell>
          <TableCell className={classes.tableRow} align="left">
            {row.customer.name}
          </TableCell>
          <TableCell className={classes.tableRow} align="center">
            {row.urlReceipt && <DownloadButton url={row.urlReceipt} />}
          </TableCell>

          <TableCell className={classes.tableRow} align="center">
            {new Intl.NumberFormat("pt-PT", {
              style: "currency",
              currency: "EUR"
            }).format(row.value)}
          </TableCell>
        </StyledTableRow>
      ))}
    </TableComponent>
  );
}

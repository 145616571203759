import { useState } from "react";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle";
import moment from "moment";
import useTransactionsPaidHistory from "../../hooks/useTransactionsPaidHistory";
import GraphHistory from "./GraphHistory";
import DateRangePickerComponent from "../../components/DateRangePicker/DateRangePicker";
import { Box, Divider, Theme, Typography } from "@mui/material";
import TableLastsResults from "./TableLastResults";
import ContainerWrapper from "../../components/ContainerWrapper/ContainerWrapper";
import AsyncResultsWrapper from "../../components/AsyncResultsWrapper/AsyncResultsWrapper";
import TableLastResult from "./TableLastResult";

export default function Resume(props: any) {
  const [dates, setDates] = useState([moment().subtract(1, "month"), moment()]);

  const { data: dataResults, loading } = useTransactionsPaidHistory({
    filters: {
      dates
    }
  });
  const sumTotal =
    dataResults &&
    dataResults.reduce((accumulator: any, object: any) => {
      return accumulator + object.value;
    }, 0);
  return (
    <ContainerWrapper>
      <HeaderTitle>Conta de Pagamentos - "empresa"</HeaderTitle>

      <Typography variant="h4">Última Transferência</Typography>
      <br />
      <AsyncResultsWrapper loading={loading} data={dataResults}>
        <TableLastResult data={dataResults} />
      </AsyncResultsWrapper>
      <br />
      <Divider />
      <br />
      <Typography variant="h4">
        Valor Líquido -{" "}
        <b>
          {new Intl.NumberFormat("pt-PT", {
            style: "currency",
            currency: "EUR"
          }).format(sumTotal)}
        </b>
      </Typography>

      <br />
      <Box sx={{ width: "300px", marginBottom: "10px" }}>
        <DateRangePickerComponent
          value={dates}
          onChange={(input: any) => setDates(input)}
        />
      </Box>
      <AsyncResultsWrapper loading={loading} data={dataResults}>
        <GraphHistory dataResults={dataResults} />
      </AsyncResultsWrapper>

      <br />
      <Divider />
      <br />
      <Typography variant="h4">Últimos 5 movimentos</Typography>
      <br />
      <AsyncResultsWrapper loading={loading} data={dataResults}>
        <TableLastsResults inputProps={dataResults} />
      </AsyncResultsWrapper>
    </ContainerWrapper>
  );
}

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colors } from "../../utils/colors";

export default function AccordionComponent({
  children,
  onClick,
  open = false,
  title,
  rootStyles
}: any) {
  return (
    <Accordion expanded={open} onChange={onClick} sx={rootStyles}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{
              ...(open && { color: "#fff" })
            }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        color="primary"
        sx={{
          backgroundColor: open
            ? "primary.main"
            : colors.standardBackgroundColor
        }}
      >
        <Typography color={open && "primary.contrastText"}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

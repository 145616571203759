import { Box, Divider, Typography } from "@mui/material";
import SelectComponent from "../Select/Select";
import Grid from "@mui/material/Unstable_Grid2";
import ResetFiltersButton from "./ResetFiltersButton";
import { getComponent } from "./utils";
import ButtonComponent from "../Button/Button";

export default function FiltersDesktop({
  state,
  filters,
  onChangeFilters,
  onChangeSort,
  sortFields,
  spaceItems,
  resultsLength,
  onChangeReset,
  buttonDownloadFile
}: any) {
  return (
    <>
      <Grid spacing={spaceItems} container>
        {filters.map((input: any) => {
          const Component: any = getComponent(input.component);
          return (
            <Grid {...input.size} key={input.id}>
              <Component
                onChange={(value: any) => {
                  onChangeFilters(input.id, value);
                }}
                value={state.filters[input.id]}
                {...input.props}
              />
            </Grid>
          );
        })}
      </Grid>
      <br />
      <Divider orientation="horizontal" flexItem />

      <br />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {resultsLength ? (
            <Typography sx={{ marginRight: "20px" }}>
              {resultsLength} Resultados
            </Typography>
          ) : null}
          {onChangeReset && <ResetFiltersButton onClick={onChangeReset} />}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {buttonDownloadFile && resultsLength ? (
            <ButtonComponent
              label={buttonDownloadFile.label}
              onClick={buttonDownloadFile.onClick}
            />
          ) : null}
          {sortFields && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"
              }}
            >
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  paddingRight: "6px"
                }}
              >
                Ordenar por:
              </Typography>
              <Box
                sx={{
                  paddingRight: "6px"
                }}
              >
                <SelectComponent
                  value={state.sort.field}
                  label="Parâmetro"
                  onChange={(value: any) => {
                    onChangeSort("field", value);
                  }}
                  items={sortFields}
                />
              </Box>
              <SelectComponent
                value={state.sort.by}
                label="Sentido"
                onChange={(value: any) => {
                  onChangeSort("by", value);
                }}
                items={[
                  {
                    label: "Ascendente",
                    value: "asc"
                  },
                  {
                    label: "Descendente",
                    value: "desc"
                  }
                ]}
              />
            </Box>
          )}
        </Box>
      </Box>
      {sortFields && (
        <>
          <br />
          <Divider />
        </>
      )}
      <br />
    </>
  );
}

const filtersMobileInputs = [
  {
    size: {
      xs: 12,
      sm: 6
    },
    id: "searchTxt",
    component: "textField",
    props: {
      label: "Pesquise pela descrição"
    }
  },
  {
    size: {
      xs: 12,
      sm: 6
    },
    id: "status",
    component: "select",
    props: {
      label: "Estado",
      items: [
        {
          label: "Pendente",
          value: "pending"
        },
        {
          label: "Aguarda Processamento",
          value: "payment_pending"
        },
        {
          label: "Erro",
          value: "payment_error"
        },
        {
          label: "Pago",
          value: "paid"
        },
        {
          label: "Expirado",
          value: "expired"
        }
      ]
    }
  },
  {
    id: "dates",
    size: {
      xs: 12,
      sm: 6
    },
    component: "dateRange",
    props: {
      titles: ["Data Inicio", "Data Fim"]
    }
  },
  {
    id: "prices",
    size: {
      xs: 12
    },
    component: "slider",
    props: {
      title: "Intervalo Valor - €",
      min: 10,
      max: 400,
      step: 10
    }
  }
];

const filtersDesktopInputs = [
  {
    size: {
      sm: 12,
      md: 12,
      lg: 3,
      xl: 3
    },
    id: "searchTxt",
    component: "textField",
    props: {
      label: "Pesquise pela descrição"
    }
  },
  {
    size: {
      sm: 4,
      md: 4,
      lg: 3
    },
    id: "status",
    component: "select",
    props: {
      label: "Estado",
      items: [
        {
          label: "Pendente",
          value: "pending"
        },
        {
          label: "Aguarda Processamento",
          value: "payment_pending"
        },
        {
          label: "Erro",
          value: "payment_error"
        },
        {
          label: "Pago",
          value: "paid"
        },
        {
          label: "Expirado",
          value: "expired"
        }
      ]
    }
  },
  {
    id: "dates",
    size: {
      sm: 4,
      md: 4,
      lg: 3
    },
    component: "dateRange",
    props: {
      titles: ["Data Inicio", "Data Fim"]
    }
  },
  {
    id: "prices",
    size: {
      sm: 4,
      md: 4,
      lg: 3
    },
    component: "slider",
    props: {
      title: "Intervalo Valor - €",
      min: 10,
      max: 400,
      step: 10
    }
  }
];

const sortFields = [
  { label: "Data do Pagamento", value: "paid_at" },
  { label: "Data do Pedido", value: "created_at" },
  { label: "Estado", value: "status" }
];

const tableHeaders: any = [
  { label: "", size: "small" },
  {
    label: "Data do Pedido"
  },
  {
    label: "Data do Pagamento"
  },
  {
    label: "Nome do Utente",
    align: "left"
  },
  {
    label: "Estado"
  },
  {
    label: "Valor"
  },
  {
    label: "Fatura-Recibo"
  }
];

export { sortFields, filtersMobileInputs, filtersDesktopInputs, tableHeaders };

import PaymentsHistory from "../../PaymentsHistory";
import TabsComponent from "../../components/Tabs/Tabs";
import Plans from "../../components/plans";

const routes = ["buy", "history"];

export default function RootCMDS(props: any) {
  const checkType = (type: "buy" | "history") => (type === "buy" ? 0 : 1);
  const currentTab = checkType(props.match.params.type);

  return (
    <TabsComponent
      value={currentTab}
      tabs={[
        {
          label: "Comprar",
          contentBody: <Plans />
        },
        {
          label: "Histórico de Compras",
          contentBody: <PaymentsHistory />
        }
      ]}
      handleChange={e => props.history.push(`/cmds/${routes[e]}`)}
    />
  );
}

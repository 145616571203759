import DateRangePickerComponent from "../DateRangePicker/DateRangePicker";
import SelectComponent from "../Select/Select";
import SliderComponent from "../Slider/Slider";
import TextFieldComponent from "../TextField/TextField";

export const getComponent = (type: any) => {
  switch (type) {
    case "select":
      return SelectComponent;
    case "slider":
      return SliderComponent;
    case "dateRange":
      return DateRangePickerComponent;
    case "textField":
      return TextFieldComponent;
  }
};

import React, { useState, useEffect, useCallback } from "react";
import IntroContainer from "../IntroContainer/IntroContainer";
import { Button } from "@mui/material";
import useFichaClinica from "../../../hooks/useFichaClinica";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useApiTwilio } from "../../../state/useApiTwilio/twilio-provider";
import { useHistory } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import HeaderTitle from "../../HeaderTitle/HeaderTitle";
import ContainerWrapper from "../../ContainerWrapper/ContainerWrapper";
import "./tabs.css";
import { useUserState } from "../../../state/user";
require("moment/locale/pt.js");

const localizer = momentLocalizer(moment);

function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function CreatePreJoinScreens() {
  const history = useHistory();
  const { loading: loadingFichaClinica, data, error } = useFichaClinica();
  const { token, loading: loadingToken } = useUserState();

  const { createRoom, roomName, loading: loadingTwilio } = useApiTwilio();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const loadingRef = loadingToken || loadingTwilio || loadingFichaClinica;

  const handleCreateVideoconsulta = async () => {
    const { roomName }: any = await createRoom(token);
    history.push(`/invite-video/${roomName}/dsadsa`);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="dashboard-tabs"
        >
          <Tab label="Consulta" {...a11yProps(0)} />
          <Tab label="Agendar Videoconsulta" {...a11yProps(1)} />
          <Tab label="TEST 3" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ContainerWrapper>
          <Calendar
            localizer={localizer}
            events={[
              {
                title: "Event 1",
                start: new Date("2023-09-23T08:30:00.000Z"),
                end: new Date("2023-09-23T14:30:00.000Z")
              },
              {
                title: "Event 2",
                start: new Date("2023-09-23T05:30:00.000Z"),
                end: new Date("2023-09-23T05:40:00.000Z")
              }
            ]}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 1000 }}
            messages={{
              next: "Próximo",
              previous: "Anterior",
              today: "Hoje",
              month: "Mês",
              week: "Semana",
              day: "Dia"
            }}
          />
        </ContainerWrapper>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        Item Two
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel>
    </>
  );
  return (
    <IntroContainer>
      <Button
        disabled={loadingRef}
        variant="contained"
        color="secondary"
        onClick={handleCreateVideoconsulta}
      >
        <Typography color="white">Criar Videoconsulta</Typography>
      </Button>
    </IntroContainer>
  );
}

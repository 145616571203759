import { Box, Divider, Typography } from "@mui/material";
import AccordionComponent from "../Accordion/Accordion";
import SelectComponent from "../Select/Select";
import Grid from "@mui/material/Unstable_Grid2";
import ResetFiltersButton from "./ResetFiltersButton";
import { getComponent } from "./utils";
import ButtonComponent from "../Button/Button";

export default function FiltersMobile({
  state,
  filters,
  onChangeFilters,
  onChangeSort,
  onChangeTypeState,
  sortFields,
  spaceItems,
  onChangeReset,
  buttonDownloadFile,
  resultsLength
}: any) {
  return (
    <>
      <AccordionComponent
        open={state.mobileExpanded}
        onClick={(e: any, isExpanded: any) => {
          onChangeTypeState("mobileExpanded", !state.mobileExpanded);
        }}
        title={"Filtros"}
        rootStyles={{ marginBottom: "16px" }}
      >
        <Box sx={{ marginBottom: "15px" }}>
          {onChangeReset && <ResetFiltersButton onClick={onChangeReset} />}
        </Box>

        <Grid spacing={spaceItems} container>
          {filters.map((input: any) => {
            const Component: any = getComponent(input.component);
            return (
              <Grid {...input.size} key={input.id}>
                <Component
                  onChange={(value: any) => onChangeFilters(input.id, value)}
                  value={state.filters[input.id]}
                  {...input.props}
                />
              </Grid>
            );
          })}
          {sortFields && (
            <>
              <Grid xs={12}>
                <Divider orientation="horizontal" flexItem />
              </Grid>
              <Grid xs={12}>
                <Typography>Ordenar:</Typography>
              </Grid>

              <Grid xs={12} sm={6}>
                <SelectComponent
                  value={state.sort.field}
                  label="Parâmetro"
                  onChange={(value: any) => {
                    onChangeSort("field", value);
                  }}
                  items={sortFields}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <SelectComponent
                  value={state.sort.by}
                  label="Sentido"
                  onChange={(value: any) => {
                    onChangeSort("by", value);
                  }}
                  items={[
                    {
                      label: "Ascendente",
                      value: "asc"
                    },
                    {
                      label: "Descendente",
                      value: "desc"
                    }
                  ]}
                />
              </Grid>
            </>
          )}
        </Grid>
      </AccordionComponent>
      {buttonDownloadFile && resultsLength ? (
        <>
          <ButtonComponent
            buttonStyles={{
              width: "100%"
            }}
            label={buttonDownloadFile.label}
            onClick={buttonDownloadFile.onClick}
          />
          <br />
          <br />
        </>
      ) : null}
    </>
  );
}

import { Box, TableCell, TableRow, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ContainerWrapper from "../../components/ContainerWrapper/ContainerWrapper";
import { isMobile } from "../../utils";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle";
import FiltersMobile from "../../components/TableFilters/FiltersMobile";
import { useFilterState } from "../../state/filters";
import {
  filtersDesktopInputs,
  filtersMobileInputs,
  sortFields,
  tableHeaders
} from "./utils";
import FiltersDesktop from "../../components/TableFilters/FiltersDesktop";
import useCobrancasHistory from "../../hooks/useCobrancasHistory";
import { formatDate } from "../../utils/date";
import Status from "../../components/Status/Status";
import DownloadButton from "../../components/DownloadButton/DownloadButton";
import Loading from "../../components/Loading/Loading";
import TableComponent, { StyledTableRow } from "../../components/Table/Table";
import Row from "./Row";

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    fontSize: "16px!important",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px!important"
    }
  }
}));

export default function ChargesHistory() {
  const classes = useStyles();
  const {
    data,
    onChangeFilters,
    onChangeSort,
    onChangeTypeState,
    onChangeResetFiltersState,
    haveActiveFilters
  } = useFilterState("cobrancas");
  const { data: dataResults, loading, error } = useCobrancasHistory({
    page: data.page,
    rowsPerPage: data.rowsPerPage,
    filters: data.filters,
    sort: data.sort
  });

  return (
    <ContainerWrapper>
      <HeaderTitle>Histórico de Pagamentos</HeaderTitle>
      {isMobile ? (
        <FiltersMobile
          state={data}
          filters={filtersMobileInputs}
          mobileExpanded={data.mobileExpanded}
          sortFields={sortFields}
          onChangeFilters={onChangeFilters}
          onChangeSort={onChangeSort}
          onChangeTypeState={onChangeTypeState}
          spaceItems={2}
          onChangeReset={haveActiveFilters ? onChangeResetFiltersState : null}
        />
      ) : (
        <FiltersDesktop
          state={data}
          filters={filtersDesktopInputs}
          sortFields={sortFields}
          onChangeFilters={onChangeFilters}
          onChangeSort={onChangeSort}
          spaceItems={3}
          resultsLength={(dataResults as any)?.items?.length}
          onChangeReset={haveActiveFilters ? onChangeResetFiltersState : null}
        />
      )}
      {loading ? (
        <Box
          alignItems={"center"}
          justifyContent={"center"}
          display={"flex"}
          marginTop={10}
        >
          <Loading />
        </Box>
      ) : !!(dataResults as any)?.items.length ? (
        <TableComponent
          headerCfg={tableHeaders}
          total={(dataResults as any)?.total}
          page={data.page}
          rowsPerPage={data.rowsPerPage}
          onChangePagination={onChangeTypeState}
        >
          {(dataResults as any).items.map((row: any) => (
            <Row row={row} />
          ))}
        </TableComponent>
      ) : (
        <>
          <br />
          <Typography>Não foram encontrados resultados.</Typography>
        </>
      )}
    </ContainerWrapper>
  );
}

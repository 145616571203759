import React from "react";
import { styled, Theme } from "@mui/material/styles";

import CreatePreJoinScreens from "./components/video/PreJoinScreens/CreatePreJoinScreens";

const Container = styled("div")({
  display: "grid",
  gridTemplateRows: "1fr auto"
});

export default function CreateVideo() {
  return (
    <Container>
      <CreatePreJoinScreens />
    </Container>
  );
}

import React, { createContext, useContext, useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { fetchUser } from "../services/user";

export const StateContext = createContext<any>(null!);

export default function UserStateProvider(props: React.PropsWithChildren<{}>) {
  const { getAccessTokenSilently } = useAuth0();
  const [user, setUser] = useState<any>(null);
  const [token, setToken] = useState<string>("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function() {
      try {
        setLoading(true);
        const tokenKey = await getAccessTokenSilently();
        if (!tokenKey) return;
        const result = await fetchUser(tokenKey);
        setToken(tokenKey);
        setUser(result);
      } catch (err) {
        setError(err as any);
      } finally {
        setLoading(false);
      }
    })();
  }, [getAccessTokenSilently]);

  const contextValue = {
    user,
    token,
    error,
    loading
  };
  return (
    <StateContext.Provider value={{ ...contextValue }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useUserState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useUserState must be used within the UserStateProvider");
  }
  return context;
}

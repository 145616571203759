import { useEffect, useState } from "react";
import { getPlans, getPurchaseHistory } from "../services/payments";
import { useUserState } from "../state/user";

export default function usePurchaseHistory() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token } = useUserState();

  useEffect(() => {
    (async function() {
      if (!token) return;
      try {
        setLoading(true);
        const result = await getPurchaseHistory({ authToken: token });
        setData(result.data);
      } catch (err) {
        setError(err as any);
      } finally {
        setLoading(false);
      }
    })();
  }, [token]);

  return { data, error, loading };
}

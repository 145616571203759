import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 150,
    width: "100%"
  }
}));

export default function SelectComponent({
  onChange,
  value,
  label,
  items
}: {
  onChange: any;
  value: string;
  label?: string;
  items: {
    label: string;
    value: number | string;
  }[];
}) {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-standard-label" color="secondary">
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={value}
        onChange={e => onChange(e.target.value)}
        label={label}
        /* className={classes.select}
        inputProps={{
          classes: {
            icon: classes.icon
          }
        }} */
      >
        {items.map(item => (
          <MenuItem key={`${item.value}`} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

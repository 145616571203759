import { useState } from "react";
import { postPurchase } from "../services/payments";
import { useUserState } from "../state/user";

export default function usePayments() {
  const [loading, setLoading] = useState(false);

  const { token } = useUserState();

  const onClickPayment = async ({
    email,
    plan
  }: {
    email: string;
    plan: string;
  }) => {
    try {
      setLoading(true);

      const result = await postPurchase({ email, plan, authToken: token });
      window.location.replace(result.url + "&callback=true");
    } catch (err) {
      console.error(err);
    }
  };

  return { onClickPayment, loading };
}

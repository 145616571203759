import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "../../icons/CloseIcon";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogComponent({
  open,
  headerTxt = "",
  handleModal,
  children
}: {
  open: boolean;
  handleModal: (state: boolean) => void;
  children: any;
  headerTxt?: string;
}) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleModal(false)}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="xs"
      PaperProps={{ sx: { borderRadius: "25px" } }}
      /* sx={{
        backdropFilter: "blur(5px) sepia(5%)"
      }} */
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
        style={{
          backgroundColor: "#e0e0e0"
        }}
      >
        <DialogTitle fontWeight={"bold"}>{headerTxt}</DialogTitle>
        <IconButton
          onClick={() => handleModal(false)}
          style={{ float: "right", margin: "12px" }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {children}
    </Dialog>
  );
}

import React from "react";
import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import Button from "@mui/material/Button";
import EndCallButton from "../Buttons/EndCallButton/EndCallButton";
import { isMobile } from "../../../utils";
import Menu from "./Menu/Menu";
import useParticipants from "../../../hooks/useParticipants/useParticipants";
import useRoomState from "../../../hooks/useRoomState/useRoomState";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { Typography, Grid, Hidden } from "@mui/material";
import ToggleAudioButton from "../Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleChatButton from "../Buttons/ToggleChatButton/ToggleChatButton";
import ToggleVideoButton from "../Buttons/ToggleVideoButton/ToggleVideoButton";
import ToggleScreenShareButton from "../Buttons/ToogleScreenShareButton/ToggleScreenShareButton";
import { useVideoState } from "../../../state";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: "fixed",
      display: "flex",
      padding: "0 1.43em",
      zIndex: 10,
      [theme.breakpoints.down("md")]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0
      }
    },
    labelButton: {
      [theme.breakpoints.down("md")]: {
        display: "none"
      }
    },
    iconStyleMicOn: {
      width: "12px",
      height: "17px",
      [theme.breakpoints.down("md")]: {
        width: "30px",
        height: "27px"
      }
    },
    iconStyleMicOff: {
      width: "25px",
      height: "24px",
      [theme.breakpoints.down("md")]: {
        width: "39px",
        height: "40px"
      }
    },
    iconStyleCamOn: {
      width: "25px",
      height: "24px",
      [theme.breakpoints.down("md")]: {
        width: "45px",
        height: "44px"
      }
    },
    iconStyleCamOff: {
      width: "12px",
      height: "17px",
      [theme.breakpoints.down("md")]: {
        width: "45px",
        height: "41px"
      }
    },
    iconStyleChat: {
      width: "19px",
      height: "17px",
      [theme.breakpoints.down("md")]: {
        width: "26px",
        height: "25px"
      }
    },
    itemMenu: {
      [theme.breakpoints.down("md")]: {
        padding: "0 30px",
        flexBasis: "90px",
        flexGrow: 0,
        flexShrink: 0
      }
    },
    screenShareBanner: {
      position: "fixed",
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: "104px",
      background: "rgba(0, 0, 0, 0.5)",
      "& h6": {
        color: "white"
      },
      "& button": {
        background: "white",
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: "0 2em",
        "&:hover": {
          color: "#600101",
          border: `2px solid #600101`,
          background: "#FFE9E7"
        }
      }
    },
    hideMobile: {
      display: "initial",
      [theme.breakpoints.down("md")]: {
        display: "none"
      }
    }
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === "reconnecting";
  const { room } = useVideoContext();
  const participants = useParticipants();
  const { isOwner } = useVideoState();

  return (
    <>
      {isSharingScreen && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.screenShareBanner}
        >
          <Typography variant="h6">Você está a partilhar ecrã</Typography>
          <Button onClick={() => toggleScreenShare()}>
            Parar partilha de ecrã
          </Button>
        </Grid>
      )}
      <footer className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden mdDown>
            <Grid style={{ flex: 1 }}>
              <Typography variant="body1">
                {participants.length + 1}{" "}
                {participants.length + 1 === 1
                  ? "participante"
                  : "participantes"}
              </Typography>
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container justifyContent="center">
              <ToggleAudioButton
                className={classes.itemMenu}
                disabled={isReconnecting}
                labelStyle={classes.labelButton}
                iconStyleMicOn={classes.iconStyleMicOn}
                iconStyleMicOff={classes.iconStyleMicOff}
              />
              <ToggleVideoButton
                className={classes.itemMenu}
                disabled={isReconnecting}
                labelStyle={classes.labelButton}
                iconStyleCamOn={classes.iconStyleCamOn}
                iconStyleCamOff={classes.iconStyleCamOff}
              />
              {!isSharingScreen && !isMobile && (
                <ToggleScreenShareButton
                  disabled={isReconnecting}
                  labelStyle={classes.labelButton}
                />
              )}
              {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !==
                "true" && (
                <ToggleChatButton
                  className={classes.itemMenu}
                  labelStyle={classes.labelButton}
                  iconStyleChat={classes.iconStyleChat}
                />
              )}
              <Hidden mdDown>
                <Menu />
              </Hidden>
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justifyContent="flex-end">
                {isOwner && <EndCallButton />}
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}

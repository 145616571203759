import moment from "moment";

function groupData(data: any, unit: any) {
  const result: any = {};

  data.forEach((res: any) => {
    const date = new Date(res.paid_at);
    let key;

    switch (unit) {
      case "days":
        key = date.toISOString().split("T")[0]; // Group by date
        break;
      case "months":
        key = `${date.getFullYear()}-${date.getMonth() + 1}`; // Group by year-month
        break;
      case "years":
        key = date.getFullYear().toString(); // Group by year
        break;
      default:
        throw new Error(
          'Invalid time unit. Please use "days", "months", or "years".'
        );
    }

    if (!result[key]) {
      result[key] = {
        dates: [],
        scriboValue: 0,
        value: 0
      };
    }

    result[key].dates.push(res.paid_at);
    result[key].scriboValue += res.scriboValue;
    result[key].value += res.value;
  });

  return result;
}

function determineTimeUnit(datesArray: any) {
  const dateObjects = datesArray.map((dateString: any) => new Date(dateString));
  const minDate = new Date(Math.min(...dateObjects)) as any;
  const maxDate = new Date(Math.max(...dateObjects)) as any;

  const dateDifferenceInDays = Math.ceil(
    (maxDate - minDate) / (1000 * 60 * 60 * 24)
  );
  const dateDifferenceInMonths =
    (maxDate.getFullYear() - minDate.getFullYear()) * 12 +
    maxDate.getMonth() -
    minDate.getMonth();

  function daysInYear(year: number) {
    return (year % 4 === 0 && year % 100 > 0) || year % 400 == 0 ? 366 : 365;
  }

  if (dateDifferenceInDays <= moment().daysInMonth()) {
    return "days"; // Group by days if the range is within a week
  } else if (dateDifferenceInMonths <= daysInYear(new Date().getFullYear())) {
    return "months"; // Group by months if the range is within six months
  } else {
    return "years"; // Otherwise, group by years
  }
}

export function formatData(data: any) {
  if (!data?.length) return;

  const dates = data.map((result: any) => result.paid_at);
  const timeUnit = determineTimeUnit(dates);

  return groupData(data, timeUnit);
}

import { useEffect, useState } from "react";
import { useUserState } from "../state/user";
import axios from "axios";
import { getPaidTransactionsHistory } from "../services/payments";

export default function useTransactionsPaidHistory({ filters }: any) {
  const [data, setData] = useState<any>(null);
  const [resultsLength, setResultsLength] = useState<any>(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token, user, loading: loadingUser } = useUserState();

  useEffect(() => {
    (async function() {
      try {
        if (loadingUser) return;

        setLoading(true);
        const result = await getPaidTransactionsHistory({
          authToken: token,
          ...(filters.dates[0] && { dataIn: filters.dates[0].toISOString() }),
          ...(filters.dates[1] && { dataEnd: filters.dates[1].toISOString() }),
          accountId: user.accountStatus.paymentId
        });
        setResultsLength(result.length);
        setData(result);
      } catch (err) {
        if (!axios.isCancel(error)) {
          return;
        }

        setError(err as any);
      } finally {
        setLoading(false);
      }
    })();
  }, [error, filters.dates, loadingUser, token, user.accountStatus.paymentId]);

  return { data, resultsLength, error, loading };
}

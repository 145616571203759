import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";

export default function MenuItemComponent({ item, onClick }: any) {
  const history = useHistory();
  const location = useLocation();

  return (
    <MenuItem
      key={item.title}
      onClick={() => {
        if (item.fn) {
          item.fn();
        } else {
          history.push(item.path);
        }

        onClick();
      }}
      selected={item.path && location.pathname === item.path}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText>{item.title}</ListItemText>
    </MenuItem>
  );
}

import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState
} from "react";
import { deepEqual } from "../utils";
import moment from "moment";
export type StructStateType<T extends any> = {
  filters: T;
  sort?: {
    field: any;
    by: "asc" | "desc";
  };
  mobileExpanded: boolean;
  page?: number;
  rowsPerPage?: number;
};
export type CobrancasStateType = StructStateType<{
  status?: string;
  searchTxt?: string;
  dates?: Array<any>;
  prices?: Array<any>;
}>;

export type CommissionsStateType = StructStateType<{
  dates?: Array<any>;
}>;

export type BillingStateType = StructStateType<{
  dates?: Array<any>;
}>;

type FiltersState = {
  cobrancas: CobrancasStateType;
  commissions: CommissionsStateType;
  billing: BillingStateType;
};

type FiltersStateProviderProps = {
  getState: (type: keyof FiltersState) => any;
  onChangeFiltersState: (type: any) => (field: any, value: any) => void;
  onChangeSortState: (type: any) => (field: any, value: any) => void;
  onChangeTypeState: (type: any) => (field: any, value: any) => void;
  onChangeResetFiltersState: (type: any) => () => void;
  haveActiveFilters: (type: any) => boolean;
  filters: FiltersState;
};

export const INITIAL_STATE_FILTERS: FiltersState = {
  cobrancas: {
    filters: {
      searchTxt: "",
      status: "",
      dates: [null, null],
      prices: [null, null]
    },
    sort: {
      field: "created_at",
      by: "desc"
    },
    page: 1,
    rowsPerPage: 10,
    mobileExpanded: false
  },
  commissions: {
    filters: {
      dates: [moment().subtract(1, "month"), moment()]
    },
    mobileExpanded: false
  },
  billing: {
    filters: {
      dates: [moment().subtract(1, "month"), moment()]
    },
    mobileExpanded: false
  }
};

export const StateContext = createContext<FiltersStateProviderProps>(null!);

export default function FiltersStateProvider(props: PropsWithChildren<{}>) {
  const [filters, setFilters] = useState<FiltersState>(INITIAL_STATE_FILTERS);

  const getState = (type: keyof FiltersState) => {
    return filters[type];
  };

  const haveActiveFilters = (type: keyof FiltersState) => {
    const { mobileExpanded: mb1, ...selectedFilters } = filters[type];
    const { mobileExpanded: mb2, ...initialFilters } = INITIAL_STATE_FILTERS[
      type
    ];

    return !deepEqual(selectedFilters, initialFilters);
  };

  const onChangeFiltersState = (type: any) => (field: any, value: any) => {
    setFilters((prevState: any) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        filters: {
          ...prevState[type].filters,
          [field]: value
        }
      }
    }));
  };

  const onChangeResetFiltersState = (type: keyof FiltersState) => () => {
    setFilters((prevState: any) => ({
      ...prevState,
      [type]: INITIAL_STATE_FILTERS[type]
    }));
  };

  const onChangeSortState = (type: any) => (field: any, value: any) => {
    setFilters((prevState: any) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        sort: {
          ...prevState[type].sort,
          [field]: value
        }
      }
    }));
  };

  const onChangeTypeState = (type: any) => (field: any, value: any) => {
    let additionalProps = {};
    if (field === "rowsPerPage") {
      additionalProps = {
        page: 1
      };
    }
    setFilters((prevState: any) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [field]: value,
        ...additionalProps
      }
    }));
  };

  const contextValue = {
    getState,
    filters,
    onChangeFiltersState,
    onChangeTypeState,
    onChangeSortState,
    onChangeResetFiltersState,
    haveActiveFilters
  };

  return (
    <StateContext.Provider value={{ ...contextValue }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useFilterState(type: keyof FiltersState) {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error(
      "useFilterState must be used within the FilterStateProvider"
    );
  }
  return {
    data: context.getState(type),
    onChangeFilters: context.onChangeFiltersState(type),
    onChangeSort: context.onChangeSortState(type),
    onChangeTypeState: context.onChangeTypeState(type),
    onChangeResetFiltersState: context.onChangeResetFiltersState(type),
    haveActiveFilters: context.haveActiveFilters(type)
  };
}

import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Header from "./Header";

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundImage: `url("/desktop-4k-min.png")`,
    height: "100vh",
    width: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.down("md")]: {
      backgroundImage: `url("/mobile.png")`
    }
  },
  center: {
    position: "fixed",
    left: "66%",
    transform: "translate(-50%, -50%)",
    width: "550px",
    [theme.breakpoints.up("lg")]: {
      left: "73%",
      top: "40%"
    },
    [theme.breakpoints.down("xl")]: {
      left: "74%",
      top: "40%"
    },
    [theme.breakpoints.down("md")]: {
      left: "50%",
      width: "80%",
      top: "48%"
    },
    [theme.breakpoints.down("sm")]: {
      left: "50%"
    }
  },
  text: {
    fontSize: "60px",
    color: "#0003c3",
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      left: "45%",
      fontSize: "55px",
      whiteSpace: "wrap"
    },
    [theme.breakpoints.down("xl")]: {
      left: "45%",
      fontSize: "55px",
      whiteSpace: "wrap"
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "45px"
    },
    [theme.breakpoints.down("md")]: {
      left: "42%",
      fontSize: "50px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px"
    }
  }
}));

export default function Disconnected() {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <Header />
      <div className={classes.center}>
        <h1 className={classes.text}>A sua videoconsulta terminou.</h1>
      </div>
    </div>
  );
}

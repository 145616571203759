import { Route, RouteProps } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../Loading/Loading";
import { Box } from "@mui/material";

export default function PrivateRoute({ component, ...rest }: RouteProps) {
  return (
    <Route
      component={withAuthenticationRequired(component as any, {
        onRedirecting: () => (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <Loading />
          </Box>
        )
      })}
      {...rest}
    />
  );
}

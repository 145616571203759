import Grid from "@mui/material/Unstable_Grid2";
import { CircularProgress, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import usePlans from "../../hooks/usePlans";
import CardComponent from "./Card";
import Loading from "../Loading/Loading";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    alignItems: "center",
    justifyContent: "center"
  }
}));

export default function Cards({
  onClickCard,
  loadingPayments,
  selectedPlan
}: any) {
  const classes = useStyles();

  const { data, loading, error } = usePlans({
    status: "active",
    type: "consumable"
  });
  const loadingRef = loading || !data;

  return (
    <Grid spacing={2} container className={classes.gridContainer}>
      {loadingRef ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={5}
        >
          <Loading />
        </Box>
      ) : (
        (data as any).items.map((data: any) => (
          <Grid xs={8} sm={5} md={2} lg={2} xl={2} key={data.name}>
            <CardComponent
              onClickCard={onClickCard}
              selectedPlan={selectedPlan}
              loadingPayments={loadingPayments}
              {...data}
            />
          </Grid>
        ))
      )}
    </Grid>
  );
}

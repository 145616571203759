import { useState } from "react";
import usePayments from "../../hooks/usePayments";
import DialogEndPayment from "./DialogEndPayment";
import Cards from "./Cards";
import HeaderTitle from "../HeaderTitle/HeaderTitle";
import ContainerWrapper from "../ContainerWrapper/ContainerWrapper";
import { useUserState } from "../../state/user";

export default function Plans() {
  const [selectedPlan, setSelectedPlan] = useState("");
  const { user } = useUserState();

  const { onClickPayment, loading: loadingPayments } = usePayments();

  const onClickCard = (id: string) => {
    const emails: any = Object.values((user as any).personalInfo.emails);

    onClickPayment({ email: emails[0].value, plan: id });
    setSelectedPlan(id);
  };

  return (
    <>
      <DialogEndPayment />
      <ContainerWrapper>
        <HeaderTitle>
          <b>Pacotes</b> de Chave Móvel Digital
        </HeaderTitle>
        <Cards
          onClickCard={onClickCard}
          loadingPayments={loadingPayments || !user}
          selectedPlan={selectedPlan}
        />
      </ContainerWrapper>
    </>
  );
}

import { useEffect, useState } from "react";
import { getPlans } from "../services/payments";

export default function usePlans({
  status,
  type
}: {
  status: "active" | "non-active";
  type: "consumable" | "subscription";
}) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function() {
      try {
        setLoading(true);
        const result = await getPlans({ status, type });
        setData(result);
      } catch (err) {
        setError(err as any);
      } finally {
        setLoading(false);
      }
    })();
  }, [status, type]);

  return { data, error, loading };
}

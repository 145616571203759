import React from "react";

import Button from "@mui/material/Button";
import MicIcon from "../../../../icons/MicIcon";
import MicOffIcon from "../../../../icons/MicOffIcon";

import useLocalAudioToggle from "../../../../hooks/useLocalAudioToggle/useLocalAudioToggle";
import useVideoContext from "../../../../hooks/useVideoContext/useVideoContext";

export default function ToggleAudioButton(props: {
  disabled?: boolean;
  className?: string;
  labelStyle?: string;
  iconStyleMicOn?: string;
  iconStyleMicOff?: string;
}) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === "audio");

  return (
    <Button
      className={props.className}
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || props.disabled}
      startIcon={
        isAudioEnabled ? (
          <MicIcon iconStyle={props.iconStyleMicOn} />
        ) : (
          <MicOffIcon iconStyle={props.iconStyleMicOff} />
        )
      }
      data-cy-audio-toggle
    >
      <span className={props.labelStyle}>
        {!hasAudioTrack
          ? "Sem microfone disponivel"
          : isAudioEnabled
          ? "Desativar microfone"
          : "Ativar microfone"}
      </span>
    </Button>
  );
}

import React from "react";
import {
  Typography,
  Grid,
  Button,
  Theme,
  Hidden,
  Switch,
  Tooltip
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import LocalVideoPreview from "./LocalVideoPreview/LocalVideoPreview";
import SettingsMenu from "./SettingsMenu/SettingsMenu";
import ToggleAudioButton from "../../Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "../../Buttons/ToggleVideoButton/ToggleVideoButton";
import { useVideoState } from "../../../../state";
import useChatContext from "../../../../hooks/useChatContext/useChatContext";
import useVideoContext from "../../../../hooks/useVideoContext/useVideoContext";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useKrispToggle } from "../../../../hooks/useKrispToggle/useKrispToggle";
import SmallCheckIcon from "../../../../icons/SmallCheckIcon";
import InfoIconOutlined from "../../../../icons/InfoIconOutlined";
import { useApiTwilio } from "../../../../state/useApiTwilio/twilio-provider";

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: "1em"
  },
  marginTop: {
    marginTop: "1em"
  },
  deviceButton: {
    width: "100%",
    border: "2px solid #aaa",
    margin: "1em 0"
  },
  localPreviewContainer: {
    paddingRight: "2em",
    marginBottom: "2em",
    [theme.breakpoints.down("md")]: {
      padding: "0 2.5em"
    }
  },
  joinButtons: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      width: "100%",
      "& button": {
        margin: "0.5em 0"
      }
    }
  },
  textJoinButtons: {
    fontSize: "25px",
    color: "white"
  },
  tooltip: {
    [theme.breakpoints.down("md")]: {
      display: "none!important"
    }
  },
  mobileButtonBar: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-between",
      margin: "1.5em 0 1em"
    }
  },
  mobileButton: {
    padding: "0.8em 0",
    margin: 0
  },
  toolTipContainer: {
    display: "flex",
    alignItems: "center",
    "& div": {
      display: "flex",
      alignItems: "center"
    },
    "& svg": {
      marginLeft: "0.3em"
    }
  }
}));

export default function DeviceSelectionScreen() {
  const classes = useStyles();
  const { roomName, twilioToken, nameUser } = useApiTwilio();
  const { isFetching, isKrispEnabled, isKrispInstalled } = useVideoState();
  const { connect: chatConnect } = useChatContext();
  const {
    connect: videoConnect,
    isAcquiringLocalTracks,
    isConnecting
  } = useVideoContext();
  const { toggleKrisp } = useKrispToggle();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  const handleJoin = () => {
    if (!twilioToken) return;
    videoConnect(twilioToken);
    chatConnect(twilioToken);
  };

  if (isFetching || isConnecting) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "100%" }}
      >
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography
            variant="body2"
            style={{ fontWeight: "bold", fontSize: "16px" }}
          >
            A entrar no consultório
          </Typography>
        </div>
      </Grid>
    );
  }
  console.log(roomName, "ROOM NAME");
  return (
    <>
      <Grid container justifyContent="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={nameUser} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton
                className={classes.mobileButton}
                disabled={disableButtons}
              />
              <ToggleVideoButton
                className={classes.mobileButton}
                disabled={disableButtons}
              />
              <SettingsMenu
                mobileButtonClass={classes.mobileButton}
                roomName={roomName}
              />
            </Hidden>
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{ alignItems: "normal" }}
          >
            <div>
              <Hidden mdDown>
                <ToggleAudioButton
                  className={classes.deviceButton}
                  disabled={disableButtons}
                />
                <ToggleVideoButton
                  className={classes.deviceButton}
                  disabled={disableButtons}
                />
              </Hidden>
            </div>
          </Grid>
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          {isKrispInstalled && (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: "1em" }}
            >
              <div className={classes.toolTipContainer}>
                <Typography variant="subtitle2">
                  Cancelamento Ruído de fundo
                </Typography>
                <Tooltip
                  title="Remova o ruido de fundo para a qualidade do seu microfone melhorar."
                  leaveDelay={250}
                  leaveTouchDelay={15000}
                  enterTouchDelay={0}
                  className={classes.tooltip}
                >
                  <div>
                    <InfoIconOutlined />
                  </div>
                </Tooltip>
              </div>

              <FormControlLabel
                control={
                  <Switch
                    checked={!!isKrispEnabled}
                    checkedIcon={<SmallCheckIcon />}
                    disableRipple={true}
                    onClick={toggleKrisp}
                  />
                }
                label={isKrispEnabled ? "Ativado" : "Desativado"}
                style={{ marginRight: 0 }}
                // Prevents <Switch /> from being temporarily enabled (and then quickly disabled) in unsupported browsers after
                // isAcquiringLocalTracks becomes false:
                disabled={isKrispEnabled && isAcquiringLocalTracks}
              />
            </Grid>
          )}
          <Divider />
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ marginTop: "1em" }}
          >
            <Hidden mdDown>
              <Grid item md={7} sm={12} xs={12}>
                <SettingsMenu
                  mobileButtonClass={classes.mobileButton}
                  roomName={roomName}
                />
              </Grid>
            </Hidden>

            <Grid item md={5} sm={12} xs={12}>
              <div className={classes.joinButtons}>
                {/*  <Button
                variant="outlined"
                color="primary"
                onClick={() => setStep(Steps.roomNameStep)}
              >
                Cancel
              </Button> */}
                <Button
                  variant="contained"
                  color="primary"
                  data-cy-join-now
                  onClick={handleJoin}
                  disabled={disableButtons}
                >
                  <span className={classes.textJoinButtons}>Entrar</span>
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

import { TablePagination } from "@mui/material";

export default function TablePaginationComponent({
  count,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange
}: any) {
  return (
    <TablePagination
      count={count}
      page={page - 1}
      onPageChange={(_, selected) => onPageChange(selected + 1)}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={e => onRowsPerPageChange(e.target.value)}
      sx={{
        ".MuiTablePagination-toolbar": {
          padding: 0
        }
      }}
    />
  );
}

import ContainerWrapper from "../../components/ContainerWrapper/ContainerWrapper";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle";
import FiltersDesktop from "../../components/TableFilters/FiltersDesktop";
import FiltersMobile from "../../components/TableFilters/FiltersMobile";
import useTransactionsPaidHistory from "../../hooks/useTransactionsPaidHistory";
import { useFilterState } from "../../state/filters";
import { isMobile } from "../../utils";
import { filtersDesktopInputs, filtersMobileInputs } from "./utils";

import AsyncResultsWrapper from "../../components/AsyncResultsWrapper/AsyncResultsWrapper";
import TableBillingHistory from "./TableBillingHistory";
import { groupByWeekAndSum } from "../CommissionsHistory/utils";

export default function BillingHistory() {
  const {
    data,
    onChangeFilters,
    onChangeTypeState,
    onChangeResetFiltersState,
    haveActiveFilters
  } = useFilterState("billing");
  const {
    data: dataResults,
    resultsLength,
    loading,
    error
  } = useTransactionsPaidHistory({
    filters: data.filters
  });
  const dataToRender = groupByWeekAndSum(dataResults);
  return (
    <ContainerWrapper>
      <HeaderTitle>Histórico de Transferências</HeaderTitle>
      {isMobile ? (
        <FiltersMobile
          state={data}
          filters={filtersMobileInputs}
          mobileExpanded={data.mobileExpanded}
          onChangeFilters={onChangeFilters}
          onChangeTypeState={onChangeTypeState}
          spaceItems={2}
          onChangeReset={haveActiveFilters ? onChangeResetFiltersState : null}
          resultsLength={resultsLength}
        />
      ) : (
        <FiltersDesktop
          state={data}
          filters={filtersDesktopInputs}
          onChangeFilters={onChangeFilters}
          spaceItems={3}
          onChangeReset={haveActiveFilters ? onChangeResetFiltersState : null}
          resultsLength={resultsLength}
        />
      )}
      <AsyncResultsWrapper loading={loading} data={dataToRender}>
        <TableBillingHistory data={dataToRender} />
      </AsyncResultsWrapper>
    </ContainerWrapper>
  );
}

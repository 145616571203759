import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Theme,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Loading from "../Loading/Loading";
import Button from "../Button/Button";

const useStyles = makeStyles(
  (theme: Theme) =>
    ({
      card: {
        borderRadius: "45px!important",
        border: "1px solid gray!important",
        backgroundColor: "#f2fff4!important"
      },
      headerTitle: {
        fontSize: "1.2rem!important",
        [theme.breakpoints.up("xl")]: {
          fontSize: "1.6rem!important"
        }
      },
      cardActions: {
        display: "block!important",
        marginBottom: "10px",
        padding: "4px!important"
      },
      price: {
        [theme.breakpoints.down("lg")]: {
          fontSize: "27px!important"
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "30px!important"
        }
      },
      button: {
        borderRadius: "40px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginLeft: "0px!important",
        justifyContent: "center"
      },
      cardContent: {
        padding: "8px!important"
      },
      btnText: {},
      imgCmd: {
        display: "flex",
        width: "100%",
        maxWidth: "90px",
        marginBottom: "8px",
        [theme.breakpoints.down("xl")]: {
          maxWidth: "60px"
        }
      },
      cmdLabel: {
        color: "#03C1AF",
        fontSize: "12px!important",
        [theme.breakpoints.up("lg")]: {
          fontSize: "14px!important"
        }
      },
      cmdRow: {
        marginRight: "10px",
        [theme.breakpoints.up("lg")]: {
          marginRight: "11px"
        }
      }
    } as any)
);

export default function CardComponent({
  onClickCard,
  id,
  prices,
  name,
  selectedPlan,
  loadingPayments
}: any) {
  const classes: any = useStyles();

  return (
    <Card className={classes.card} elevation={24}>
      <CardHeader
        title={name}
        sx={{
          textAlign: "center",
          backgroundColor: theme => theme.palette.grey[300],
          padding: "6px",
          fontWeight: 200
        }}
        classes={{
          title: classes.headerTitle
        }}
      />
      <CardContent className={classes.cardContent}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline"
          }}
        >
          <Typography
            component="h2"
            variant="h4"
            color="text.primary"
            className={classes.price}
          >
            {new Intl.NumberFormat("pt-PT", {
              style: "currency",
              currency: "EUR"
            }).format(prices.price)}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions className={classes.cardActions}>
        <Box
          alignItems="center"
          justifyContent="center"
          display="flex"
          className={classes.cmdRow}
        >
          <img src="/CMD_symbol.png" className={classes.imgCmd} />
          <Typography variant="h6" className={classes.cmdLabel}>
            <b>CHAVE MÓVEL</b>
          </Typography>
        </Box>

        <Box className={classes.button}>
          <Button
            buttonStyles={{
              width: "65%"
            }}
            onClick={() => onClickCard(id)}
            disabled={loadingPayments}
            selected={selectedPlan === id}
            label="Comprar"
          />
        </Box>
      </CardActions>
    </Card>
  );
}
